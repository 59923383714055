// LABELS
// ------
@import "variables.scss"; // Modify this for custom colors, font-sizes, etc
@import "mixins.scss";

// Base
.label {
  padding: 1px 4px 2px;
  font-size: $baseFontSize * .846;
  font-weight: bold;
  line-height: 13px; // ensure proper line-height if floated
  color: $white;
  vertical-align: middle;
  white-space: nowrap;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  background-color: $grayLight;
  @include border-radius(3px);
}

// Hover state
.label:hover {
  color: $white;
  text-decoration: none;
}

// Colors
.label-important        { background-color: $errorText; }
.label-important:hover  { background-color: darken($errorText, 10%); }

.label-warning          { background-color: $orange; }
.label-warning:hover    { background-color: darken($orange, 10%); }

.label-success          { background-color: $successText; }
.label-success:hover    { background-color: darken($successText, 10%); }

.label-info             { background-color: $infoText; }
.label-info:hover       { background-color: darken($infoText, 10%); }

.label-inverse             { background-color: $grayDark; }
.label-inverse:hover       { background-color: darken($grayDark, 10%); }