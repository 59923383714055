// Mixins.scss
// Snippets of reusable CSS to develop faster and keep code readable
// -----------------------------------------------------------------


// UTILITY MIXINS
// --------------------------------------------------

// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
.clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}

@mixin clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}

// Webkit-style focus
// ------------------
@mixin tab-focus() {
  // Default
  outline: thin dotted #333;
  // Webkit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

// Center-align a block level element
// ----------------------------------
@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// IE7 inline-block
// ----------------
@mixin ie7-inline-block() {
  *display: inline; /* IE7 inline-block hack */
  *zoom: 1;
}

// IE7 likes to collapse whitespace on either side of the inline-block elements.
// Ems because we're attempting to match the width of a space character. Left
// version is for form buttons, which typically come after other elements, and
// right version is for icons, which come before. Applying both is ok, but it will
// mean that space between those elements will be .6em (~2 space characters) in IE7,
// instead of the 1 space in other browsers.
@mixin ie7-restore-left-whitespace() {
  *margin-left: .3em;

  &:first-child {
    *margin-left: 0;
  }
}

@mixin ie7-restore-right-whitespace() {
  *margin-right: .3em;

  &:last-child {
    *margin-left: 0;
  }
}

// Sizing shortcuts
// -------------------------
@mixin size($height: 5px, $width: 5px) {
  width: $width;
  height: $height;
}
@mixin square($size: 5px) {
  @include size($size, $size);
}

// Placeholder text
// -------------------------
@mixin placeholder($color: $placeholderText) {
  :-moz-placeholder {
    color: $color;
  }
  ::-webkit-input-placeholder {
    color: $color;
  }
}

// Text overflow
// -------------------------
// Requires inline-block or block for proper styling
@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// New image replacement
// -------------------------
// Source: http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
.hide-text {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}


// FONTS
// --------------------------------------------------


@mixin shorthand($size: $baseFontSize, $weight: normal, $lineHeight: $baseLineHeight) {
  font-size: $size;
  font-weight: $weight;
  line-height: $lineHeight;
}
@mixin serif($size: $baseFontSize, $weight: normal, $lineHeight: $baseLineHeight) {
  font-family: Georgia, "Times New Roman", Times, serif;
  @include shorthand($size, $weight, $lineHeight);
}
@mixin sans-serif($size: $baseFontSize, $weight: normal, $lineHeight: $baseLineHeight) {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  @include shorthand($size, $weight, $lineHeight);
}
@mixin monospace($size: $baseFontSize, $weight: normal, $lineHeight: $baseLineHeight) {
  font-family: Menlo, Monaco, "Courier New", monospace;
  @include shorthand($size, $weight, $lineHeight);
}
// #font {
//   #family {
//     @mixin serif() {
//       font-family: Georgia, "Times New Roman", Times, serif;
//     }
//     @mixin sans-serif() {
//       font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
//     }
//     @mixin monospace() {
//       font-family: Menlo, Monaco, "Courier New", monospace;
//     }
//   }
//   @mixin shorthand($size: $baseFontSize, $weight: normal, $lineHeight: $baseLineHeight) {
//     font-size: $size;
//     font-weight: $weight;
//     line-height: $lineHeight;
//   }
//   @mixin serif($size: $baseFontSize, $weight: normal, $lineHeight: $baseLineHeight) {
//     #font > #family > .serif;
//     @include shorthand($size, $weight, $lineHeight);
//   }
//   @mixin sans-serif($size: $baseFontSize, $weight: normal, $lineHeight: $baseLineHeight) {
//     #font > #family > .sans-serif;
//     @include shorthand($size, $weight, $lineHeight);
//   }
//   @mixin monospace($size: $baseFontSize, $weight: normal, $lineHeight: $baseLineHeight) {
//     #font > #family > .monospace;
//     @include shorthand($size, $weight, $lineHeight);
//   }
// }


// FORMS
// --------------------------------------------------

// Block level inputs
// .input-block-level {
//   display: block;
//   width: 100%;
//   min-height: 28px; /* Make inputs at least the height of their button counterpart */
//   /* Makes inputs behave like true block-level elements */
//   @include box-sizing(border-box);
// }


// Mixin for form field states
@mixin formFieldState($textColor: #555, $borderColor: #ccc, $backgroundColor: #f5f5f5) {
  // Set the text color
  > label,
  .help-block,
  .help-inline {
    color: $textColor;
  }
  // Style inputs accordingly
  input,
  select,
  textarea {
    color: $textColor;
    border-color: $borderColor;
    &:focus {
      border-color: darken($borderColor, 10%);
      @include box-shadow(0 0 6px lighten($borderColor, 20%));
    }
  }
  // Give a small background color for input-prepend/-append
  .input-prepend .add-on,
  .input-append .add-on {
    color: $textColor;
    background-color: $backgroundColor;
    border-color: $textColor;
  }
}



// CSS3 PROPERTIES
// --------------------------------------------------

// Appearance
@mixin appearance($appearance) {
  -webkit-appearance: $appearance;
     -moz-appearance: $appearance;
          appearance: $appearance;
}

// Border Radius
@mixin border-radius($radius: 5px) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
}

// Drop shadows
@mixin box-shadow($shadow: 0 1px 3px rgba(0,0,0,.25)) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}

// Transitions
@mixin transition($transition) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
      -ms-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}

// Multiple transitions
// @mixin transitions(...) {
//   $transition: ~`"#{$arguments}".replace(/[\[\]]/g, '')`;
//   -webkit-transition: $transition;
//      -moz-transition: $transition;
//       -ms-transition: $transition;
//        -o-transition: $transition;
//           transition: $transition;
// }

// Transform-style
@mixin transform-style($transform-style) {
  -webkit-transform-style: $transform-style;
     -moz-transform-style: $transform-style;
      -ms-transform-style: $transform-style;
       -o-transform-style: $transform-style;
          transform-style: $transform-style;
}

// Backface-visibility
@mixin backface-visibility($backface-visibility) {
  -webkit-backface-visibility: $backface-visibility;
     -moz-backface-visibility: $backface-visibility;
      -ms-backface-visibility: $backface-visibility;
       -o-backface-visibility: $backface-visibility;
          backface-visibility: $backface-visibility;
}

// Transformations
// Transform
@mixin transform($transformation) {
  -webkit-transform: $transformation;
     -moz-transform: $transformation;
      -ms-transform: $transformation;
       -o-transform: $transformation;
          transform: $transformation;
}

// Transform Origin
@mixin transform-origin($transform-origin) {
  -webkit-transform-origin: $transform-origin;
     -moz-transform-origin: $transform-origin;
      -ms-transform-origin: $transform-origin;
       -o-transform-origin: $transform-origin;
          transform-origin: $transform-origin;
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
     -moz-transform: rotate($degrees);
      -ms-transform: rotate($degrees);
       -o-transform: rotate($degrees);
          transform: rotate($degrees);
}
@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
     -moz-transform: scale($ratio);
      -ms-transform: scale($ratio);
       -o-transform: scale($ratio);
          transform: scale($ratio);
}

@mixin stretch($x: 1, $y: 1) {
  -webkit-transform: scale($x, $y);
     -moz-transform: scale($x, $y);
      -ms-transform: scale($x, $y);
       -o-transform: scale($x, $y);
          transform: scale($x, $y);
}

@mixin translate($x: 0, $y: 0) {
  -webkit-transform: translate($x, $y);
     -moz-transform: translate($x, $y);
      -ms-transform: translate($x, $y);
       -o-transform: translate($x, $y);
          transform: translate($x, $y);
}

@mixin transform-origin($x:center, $y:center) {
  -webkit-transform-origin: $x $y;
     -moz-transform-origin: $x $y;
      -ms-transform-origin: $x $y;
       -o-transform-origin: $x $y;
          transform-origin: $x $y;
}
@mixin skew($x: 0, $y: 0) {
  -webkit-transform: skew($x, $y);
     -moz-transform: skew($x, $y);
      -ms-transform: skew($x, $y);
       -o-transform: skew($x, $y);
          transform: skew($x, $y);
}
@mixin translate3d($x: 0, $y: 0, $z: 0) {
  -webkit-transform: translate($x, $y, $z);
     -moz-transform: translate($x, $y, $z);
      -ms-transform: translate($x, $y, $z);
       -o-transform: translate($x, $y, $z);
          transform: translate($x, $y, $z);
}

// Background clipping
// Heads up: FF 3.6 and under need "padding" instead of "padding-box"
@mixin background-clip($clip) {
  -webkit-background-clip: $clip;
     -moz-background-clip: $clip;
          background-clip: $clip;
}

// Background sizing
@mixin background-size($size) {
  -webkit-background-size: $size;
     -moz-background-size: $size;
       -o-background-size: $size;
          background-size: $size;
}


// Box sizing
@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
     -moz-box-sizing: $boxmodel;
      -ms-box-sizing: $boxmodel;
          box-sizing: $boxmodel;
}

// User select
// For selecting text on the page
@mixin user-select($select) {
  -webkit-user-select: $select;
     -moz-user-select: $select;
       -o-user-select: $select;
          user-select: $select;
}

// Resize anything
@mixin resizable($direction: both) {
  resize: $direction; // Options: horizontal, vertical, both
  overflow: auto; // Safari fix
}

// CSS3 Content Columns
@mixin content-columns($columnCount, $columnGap: $gridColumnGutter) {
  -webkit-column-count: $columnCount;
     -moz-column-count: $columnCount;
          column-count: $columnCount;
  -webkit-column-gap: $columnGap;
     -moz-column-gap: $columnGap;
          column-gap: $columnGap;
}

// Opacity
@mixin opacity($opacity: 100) {
  opacity: $opacity * 0.01;
   filter: "alpha(opacity=#{$opacity})";
}

// Blur
@mixin blur($radius: 14px) {
  -webkit-filter: blur($radius);
  -moz-filter: blur($radius);
  -o-filter: blur($radius);
  -ms-filter: blur($radius);
}

// Hyphens
@mixin hyphens($value) {
  -webkit-hyphens: $value;
     -moz-hyphens: $value;
      -ms-hyphens: $value;
          hyphens: $value;
}


// BACKGROUNDS
// --------------------------------------------------

// Add an alphatransparency value to any background or border color (via Elyse Holladay)
#translucent {
  @mixin background($color: $white, $alpha: 1) {
    background-color: hsla(hue($color), saturation($color), lightness($color), $alpha);
  }
  @mixin border($color: $white, $alpha: 1) {
    border-color: hsla(hue($color), saturation($color), lightness($color), $alpha);
    @include background-clip(padding-box);
  }
}

// Gradient Bar Colors for buttons and alerts
@mixin gradientBar($primaryColor, $secondaryColor) {
  @include vertical-gradient($primaryColor, $secondaryColor);
  border-color: $secondaryColor $secondaryColor darken($secondaryColor, 15%);
  border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) fadein(rgba(0,0,0,.1), 15%);
}

// Gradients
@mixin horizontal-gradient($startColor: #555, $endColor: #333) {
  background-color: $endColor;
  background-image: -moz-linear-gradient(left, $startColor, $endColor); // FF 3.6+
  background-image: -ms-linear-gradient(left, $startColor, $endColor); // IE10
  background-image: -webkit-gradient(linear, 0 0, 100% 0, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(left, $startColor, $endColor); // Safari 5.1+, Chrome 10+
  background-image: -o-linear-gradient(left, $startColor, $endColor); // Opera 11.10
  background-image: linear-gradient(left, $startColor, $endColor); // Le standard
  background-repeat: repeat-x;
  // filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",$startColor,$endColor)); // IE9 and down
}
@mixin vertical-gradient($startColor: #555, $endColor: #333) {
  background-color: mix($startColor, $endColor, 60%);
  background-image: -moz-linear-gradient(top, $startColor, $endColor); // FF 3.6+
  background-image: -ms-linear-gradient(top, $startColor, $endColor); // IE10
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, $startColor, $endColor); // Safari 5.1+, Chrome 10+
  background-image: -o-linear-gradient(top, $startColor, $endColor); // Opera 11.10
  background-image: linear-gradient(top, $startColor, $endColor); // The standard
  background-repeat: repeat-x;
  // filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",$startColor,$endColor)); // IE9 and down
}
@mixin directional-gradient($startColor: #555, $endColor: #333, $deg: 45deg) {
  background-color: $endColor;
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient($deg, $startColor, $endColor); // FF 3.6+
  background-image: -ms-linear-gradient($deg, $startColor, $endColor); // IE10
  background-image: -webkit-linear-gradient($deg, $startColor, $endColor); // Safari 5.1+, Chrome 10+
  background-image: -o-linear-gradient($deg, $startColor, $endColor); // Opera 11.10
  background-image: linear-gradient($deg, $startColor, $endColor); // The standard
}
@mixin vertical-three-colors-gradient($startColor: #00b3ee, $midColor: #7a43b6, $colorStop: 50%, $endColor: #c3325f) {
  background-color: mix($midColor, $endColor, 80%);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), color-stop($colorStop, $midColor), to($endColor));
  background-image: -webkit-linear-gradient($startColor, $midColor $colorStop, $endColor);
  background-image: -moz-linear-gradient(top, $startColor, $midColor $colorStop, $endColor);
  background-image: -ms-linear-gradient($startColor, $midColor $colorStop, $endColor);
  background-image: -o-linear-gradient($startColor, $midColor $colorStop, $endColor);
  background-image: linear-gradient($startColor, $midColor $colorStop, $endColor);
  background-repeat: no-repeat;
  // filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",$startColor,$endColor)); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin radial-gradient1($innerColor: #555, $outerColor: #333) {
  background-color: $outerColor;
  background-image: -webkit-gradient(radial, center center, 0, center center, 460, from($innerColor), to($outerColor));
  background-image: -webkit-radial-gradient(circle, $innerColor, $outerColor);
  background-image: -moz-radial-gradient(circle, $innerColor, $outerColor);
  background-image: -ms-radial-gradient(circle, $innerColor, $outerColor);
  background-image: -o-radial-gradient(circle, $innerColor, $outerColor);
  background-repeat: no-repeat;
}
@mixin radial-gradient($location, $color1, $color2, $color3) {
  background: -o-radial-gradient($location, $color1, $color2, $color3);
  background: -moz-radial-gradient($location, $color1, $color2, $color3);
  background: -webkit-radial-gradient($location, $color1, $color2, $color3);
  background: radial-gradient($location, $color1, $color2, $color3);
}
@mixin striped-gradient($color, $angle: -45deg) {
  background-color: $color;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255,255,255,.15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255,255,255,.15)), color-stop(.75, rgba(255,255,255,.15)), color-stop(.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  background-image: -ms-linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
}

// Reset filters for IE
@mixin reset-filter() {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}



// COMPONENT MIXINS
// --------------------------------------------------

// Horizontal dividers
// -------------------------
// Dividers (basically an hr) within dropdowns and nav lists
@mixin nav-divider() {
  height: 1px;
  margin: (($baseLineHeight * 0.5) - 1) 1px; // 8px 1px
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid $white;

  // IE7 needs a set width since we gave a height. Restricting just
  // to IE7 to keep the 1px left/right space in other browsers.
  // It is unclear where IE is getting the extra space that we need
  // to negative-margin away, but so it goes.
  *width: 100%;
  *margin: -5px 0 5px;
}

// Button backgrounds
// ------------------
@mixin buttonBackground($startColor, $endColor) {
  // gradientBar will set the background to a pleasing blend of these, to support IE<=9
  @include gradientBar($startColor, $endColor);
  @include reset-filter();

  // in these cases the gradient won't cover the background, so we override
  &:hover, &:active, &.active, &.disabled, &[disabled] {
    background-color: $endColor;
  }

  // IE 7 + 8 can't handle box-shadow to show active, so we darken a bit ourselves
  &:active,
  &.active {
    background-color: darken($endColor, 10%) e("\9");
  }
}

// Navbar vertical align
// -------------------------
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `.navbarVerticalAlign(30px);` to calculate the appropriate top margin.
@mixin navbarVerticalAlign($elementHeight) {
  margin-top: ($navbarHeight - $elementHeight) * 0.5;
}

// Popover arrows
// -------------------------
// For tipsies and popovers
@mixin popover-top($arrowWidth: 5px, $color: $black) {
  bottom: 0;
  left: 50%;
  margin-left: -$arrowWidth;
  border-left: $arrowWidth solid transparent;
  border-right: $arrowWidth solid transparent;
  border-top: $arrowWidth solid $color;
}
@mixin popover-left($arrowWidth: 5px, $color: $black) {
  top: 50%;
  right: 0;
  margin-top: -$arrowWidth;
  border-top: $arrowWidth solid transparent;
  border-bottom: $arrowWidth solid transparent;
  border-left: $arrowWidth solid $color;
}
@mixin popover-bottom($arrowWidth: 5px, $color: $black) {
  top: 0;
  left: 50%;
  margin-left: -$arrowWidth;
  border-left: $arrowWidth solid transparent;
  border-right: $arrowWidth solid transparent;
  border-bottom: $arrowWidth solid $color;
}
@mixin popover-right($arrowWidth: 5px, $color: $black) {
  top: 50%;
  left: 0;
  margin-top: -$arrowWidth;
  border-top: $arrowWidth solid transparent;
  border-bottom: $arrowWidth solid transparent;
  border-right: $arrowWidth solid $color;
}


// Grid System
// -----------

// Centered container element
@mixin container-fixed() {
  margin-left: auto;
  margin-right: auto;
  // @mixin clearfix()
}

// Table columns
@mixin tableColumns($columnSpan: 1) {
  float: none; // undo default grid column styles
  width: (($gridColumnWidth) * $columnSpan) + ($gridGutterWidth * ($columnSpan - 1)) - 16; // 16 is total padding on left and right of table cells
  margin-left: 0; // undo default grid column styles
}

// Make a Grid
// Use .makeRow and .makeColumn to assign semantic layouts grid system behavior
@mixin makeRow() {
  margin-left: $gridGutterWidth * -1;
  // @mixin clearfix(),
}
@mixin makeColumn($columns: 1) {
  float: left;
  margin-left: $gridGutterWidth;
  width: ($gridColumnWidth * $columns) + ($gridGutterWidth * ($columns - 1));
}

// The Grid
// #grid {

//   @mixin core($gridColumnWidth, $gridGutterWidth) {

//     @mixin spanX($index) when ($index > 0) {
//       (".span#{$index}") { .span($index); }
//       @mixin spanX($index - 1),
//     }
//     @mixin spanX(0) {}

//     .offsetX ($index) when ($index > 0) {
//       (".offset#{$index}") { .offset($index); }
//       @mixin offsetX($index - 1),
//     }
//     @mixin offsetX(0) {}

//     .offset ($columns) {
//       margin-left: ($gridColumnWidth * $columns) + ($gridGutterWidth * ($columns - 1)) + ($gridGutterWidth * 2);
//     }

//     @mixin span($columns) {
//       width: ($gridColumnWidth * $columns) + ($gridGutterWidth * ($columns - 1));
//     }

//     .row {
//       margin-left: $gridGutterWidth * -1;
//       @include clearfix();
//     }

[class*="span"] {
  float: left;
  margin-left: 20px;
}

//     // Set the container width, and override it for fixed navbars in media queries
//     .container,
//     .navbar-fixed-top .container,
//     .navbar-fixed-bottom .container { .span($gridColumns); }

//     // generate .spanX and .offsetX
//     @include spanX ($gridColumns);
//     @include offsetX ($gridColumns);

//   }

//   @mixin fluid($fluidGridColumnWidth, $fluidGridGutterWidth) {

//     @mixin spanX($index) when ($index > 0) {
//       ("> .span#{$index}") { .span($index); }
//       @mixin spanX($index - 1),
//     }
//     @mixin spanX(0) {}

//     .span ($columns) {
//       width: ($fluidGridColumnWidth * $columns) + ($fluidGridGutterWidth * ($columns - 1));
//     }

//     .row-fluid {
//       width: 100%;
//       @include clearfix();
//       > [class*="span"] {
//         float: left;
//         margin-left: $fluidGridGutterWidth;
//       }
//       > [class*="span"]:first-child {
//         margin-left: 0;
//       }

//       // generate .spanX
//       @include spanX ($gridColumns);
//     }

//   }

//   @mixin input($gridColumnWidth, $gridGutterWidth) {

//     @mixin spanX($index) when ($index > 0) {
//       ("input.span#{$index}, textarea.span#{$index}, .uneditable-input.span#{$index}") { .span($index); }
//       @mixin spanX($index - 1),
//     }
//     @mixin spanX(0) {}

//     .span($columns) {
//       width: (($gridColumnWidth) * $columns) + ($gridGutterWidth * ($columns - 1)) - 10;
//     }

.span12 {
  width: 940px;
}
.span11 {
  width: 860px;
}
.span10 {
  width: 780px;
}
.span9 {
  width: 700px;
}
.span8 {
  width: 620px;
}
.span7 {
  width: 540px;
}
.span6 {
  width: 460px;
}
.span5 {
  width: 380px;
}
.span4 {
  width: 300px;
}
.span3 {
  width: 220px;
}
.span2 {
  width: 140px;
}
.span1 {
  width: 60px;
}

//     input,
//     textarea,
//     .uneditable-input {
//       margin-left: 0; // override margin-left from core grid system
//     }

//     // generate .spanX
//     @include spanX ($gridColumns);

//   }

// }

// Font smoothing
@mixin font-smoothing($moz: grayscale, $webkit: antialiased) {
  -moz-osx-font-smoothing: $moz;
  -webkit-font-smoothing: $webkit;
}
