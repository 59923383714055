// Forms.scss
// Base styles for various input types, form layouts, and states
// -------------------------------------------------------------
@import "variables.scss"; // Modify this for custom colors, font-sizes, etc
@import "mixins.scss";


// GENERAL STYLES
// --------------

// Make all forms have space below them
form {
  margin: 0 0 $baseLineHeight;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

// Groups of fields with labels on top (legends)
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: $baseLineHeight * 1.5;
  font-size: $baseFontSize * 1.5;
  line-height: $baseLineHeight * 2;
  color: $grayDark;
  border: 0;
  border-bottom: 1px solid #eee;

  // Small
  small {
    font-size: $baseLineHeight * .75;
    color: $grayLight;
  }
}

// Set font for forms
label,
input,
button,
select,
textarea {
  @include shorthand($baseFontSize,normal,$baseLineHeight); // Set size, weight, line-height here
}
input,
button,
select,
textarea {
  font-family: $baseFontFamily; // And only set font-family here for those that need it (note the missing label element)
}

// Identify controls by their labels
label {
  display: block;
  margin-bottom: 5px;
  color: $grayDark;
}

// Inputs, Textareas, Selects
input,
textarea,
select,
.uneditable-input {
  display: inline-block;
  width: 210px;
  height: $baseLineHeight;
  padding: 4px;
  margin-bottom: 9px;
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: $gray;
  border: 1px solid $inputBorder;
  @include border-radius(3px);
}
.uneditable-textarea {
  width: auto;
  height: auto;
}

// Inputs within a label
label input,
label textarea,
label select {
  display: block;
}

// Mini reset for unique input types
input[type="image"],
input[type="checkbox"],
input[type="radio"] {
  width: auto;
  height: auto;
  padding: 0;
  margin: 3px 0;
  *margin-top: 0; /* IE7 */
  line-height: normal;
  cursor: pointer;
  @include border-radius(0);
  border: 0 \9; /* IE9 and down */
}
input[type="image"] {
  border: 0;
}

// Reset the file input to browser defaults
input[type="file"] {
  width: auto;
  padding: initial;
  line-height: initial;
  border: initial;
  background-color: $inputBackground;
  background-color: initial;
  @include box-shadow(none);
}

// Help out input buttons
input[type="button"],
input[type="reset"],
input[type="submit"] {
  width: auto;
  height: auto;
}

// Set the height of select and file controls to match text inputs
select,
input[type="file"] {
  height: 28px; /* In IE7, the height of the select element cannot be changed by height, only font-size */
  *margin-top: 4px; /* For IE7, add top margin to align select with labels */
  line-height: 28px;
}

// Reset line-height for IE
input[type="file"] {
  line-height: 18px \9;
}

// Chrome on Linux and Mobile Safari need background-color
select {
  width: 220px; // default input width + 10px of padding that doesn't get applied
  background-color: $inputBackground;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
  height: auto;
}

// Remove shadow from image inputs
input[type="image"] {
  @include box-shadow(none);
}

// Make textarea height behave
textarea {
  height: auto;
}

// Hidden inputs
input[type="hidden"] {
  display: none;
}



// CHECKBOXES & RADIOS
// -------------------

// Indent the labels to position radios/checkboxes as hanging
.radio,
.checkbox {
  padding-left: 18px;
}
.radio input[type="radio"],
.checkbox input[type="checkbox"] {
  float: left;
  margin-left: -18px;
}

// Move the options list down to align with labels
.controls > .radio:first-child,
.controls > .checkbox:first-child {
  padding-top: 5px; // has to be padding because margin collaspes
}

// Radios and checkboxes on same line
// TODO v3: Convert .inline to .control-inline
.radio.inline,
.checkbox.inline {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
  margin-left: 10px; // space out consecutive inline controls
}



// FOCUS STATE
// -----------

input,
textarea {
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  $transition: border linear .2s, box-shadow linear .2s, background-color linear .2s;
  @include transition($transition);
}
input:focus,
textarea:focus {
  border-color: rgba(82,168,236,.8);
  $shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  @include box-shadow($shadow);
  outline: 0;
  outline: thin dotted \9; /* IE6-9 */
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
select:focus {
  @include box-shadow(none); // override for file inputs
  @include tab-focus();
}



// INPUT SIZES
// -----------

// General classes for quick sizes
.input-mini       { width: 60px; }
.input-small      { width: 90px; }
.input-medium     { width: 150px; }
.input-large      { width: 210px; }
.input-xlarge     { width: 270px; }
.input-xxlarge    { width: 530px; }

// Grid style input sizes
input[class*="span"],
select[class*="span"],
textarea[class*="span"],
.uneditable-input {
  float: none;
  margin-left: 0;
}

// DISABLED STATE
// --------------

// Disabled and read-only inputs
input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  background-color: $inputDisabledBackground;
  border-color: #ddd;
  cursor: not-allowed;
}




// FORM FIELD FEEDBACK STATES
// --------------------------

// Warning
.control-group.warning {
  @include formFieldState($warningText, $warningText, $warningBackground);
}
// Error
.control-group.error {
  @include formFieldState($errorText, $errorText, $errorBackground);
}
// Success
.control-group.success {
  @include formFieldState($successText, $successText, $successBackground);
}

// HTML5 invalid states
// Shares styles with the .control-group.error above
input:focus:required:invalid,
textarea:focus:required:invalid,
select:focus:required:invalid {
  color: #b94a48;
  border-color: #ee5f5b;
  &:focus {
    border-color: darken(#ee5f5b, 10%);
    @include box-shadow(0 0 6px lighten(#ee5f5b, 20%));
  }
}



// FORM ACTIONS
// ------------

.form-actions {
  padding: ($baseLineHeight - 1) 20px $baseLineHeight;
  margin-top: $baseLineHeight;
  margin-bottom: $baseLineHeight;
  background-color: $grayLighter;
  border-top: 1px solid #ddd;
  @include clearfix(); // Adding clearfix to allow for .pull-right button containers
}

// For text that needs to appear as an input but should not be an input
.uneditable-input {
  display: block;
  background-color: $inputBackground;
  border-color: #eee;
  @include box-shadow(inset 0 1px 2px rgba(0,0,0,.025));
  cursor: not-allowed;
}

// Placeholder text gets special styles; can't be bundled together though for some reason
@include placeholder($grayLight);



// HELP TEXT
// ---------

.help-block,
.help-inline {
  color: $gray; // lighten the text some for contrast
}

.help-block {
  display: block; // account for any element using help-block
  margin-bottom: $baseLineHeight * 0.5;
}

.help-inline {
  display: inline-block;
  @include ie7-inline-block();
  vertical-align: middle;
  padding-left: 5px;
}



// INPUT GROUPS
// ------------

// Allow us to put symbols and text within the input field for a cleaner look
.input-prepend,
.input-append {
  margin-bottom: 5px;
  input,
  select,
  .uneditable-input {
    *margin-left: 0;
    @include border-radius(0 3px 3px 0);
    &:focus {
      position: relative;
      z-index: 2;
    }
  }
  .uneditable-input {
    border-left-color: #ccc;
  }
  .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: $baseLineHeight;
    padding: 4px 5px;
    font-weight: normal;
    line-height: $baseLineHeight;
    text-align: center;
    text-shadow: 0 1px 0 $white;
    vertical-align: middle;
    background-color: $grayLighter;
    border: 1px solid #ccc;
  }
  .add-on,
  .btn {
    @include border-radius(3px 0 0 3px);
  }
  .active {
    background-color: lighten($green, 30);
    border-color: $green;
  }
}
.input-prepend {
  .add-on,
  .btn {
    margin-right: -1px;
  }
}
.input-append {
  input,
  select
  .uneditable-input {
    @include border-radius(3px 0 0 3px);
  }
  .uneditable-input {
    border-left-color: #eee;
    border-right-color: #ccc;
  }
  .add-on,
  .btn {
    margin-left: -1px;
    @include border-radius(0 3px 3px 0);
  }
}
// Remove all border-radius for inputs with both prepend and append
.input-prepend.input-append {
  input,
  select,
  .uneditable-input {
    @include border-radius(0);
  }
  .add-on:first-child,
  .btn:first-child {
    margin-right: -1px;
    @include border-radius(3px 0 0 3px);
  }
  .add-on:last-child,
  .btn:last-child {
    margin-left: -1px;
    @include border-radius(0 3px 3px 0);
  }
}



// SEARCH FORM
// -----------

.search-query {
  padding-left: 14px;
  padding-right: 14px;
  margin-bottom: 0; // remove the default margin on all inputs
  @include border-radius(14px);
}



// HORIZONTAL & VERTICAL FORMS
// ---------------------------

// Common properties
// -----------------

.form-search,
.form-inline,
.form-horizontal {
  input,
  textarea,
  select,
  .help-inline,
  .uneditable-input,
  .input-prepend,
  .input-append {
    display: inline-block;
    margin-bottom: 0;
  }
  // Re-hide hidden elements due to specifity
  .hide {
    display: none;
  }
}
.form-search label,
.form-inline label {
  display: inline-block;
}
// Remove margin for input-prepend/-append
.form-search .input-append,
.form-inline .input-append,
.form-search .input-prepend,
.form-inline .input-prepend {
  margin-bottom: 0;
}
// Inline checkbox/radio labels (remove padding on left)
.form-search .radio,
.form-search .checkbox,
.form-inline .radio,
.form-inline .checkbox {
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;
}
// Remove float and margin, set to inline-block
.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
  float: left;
  margin-left: 0;
  margin-right: 3px;
}


// Margin to space out fieldsets
.control-group {
  margin-bottom: $baseLineHeight * 0.5;
}

// Legend collapses margin, so next element is responsible for spacing
legend + .control-group {
  margin-top: $baseLineHeight;
  -webkit-margin-top-collapse: separate;
}

// Horizontal-specific styles
// --------------------------

.form-horizontal {
  // Increase spacing between groups
  .control-group {
    margin-bottom: $baseLineHeight;
    @include clearfix();
  }
  // Float the labels left
  .control-label {
    float: left;
    width: 140px;
    padding-top: 5px;
    text-align: right;
  }
  // Move over all input controls and content
  .controls {
    margin-left: 160px;
    /* Super jank IE7 fix to ensure the inputs in .input-append and input-prepend don't inherit the margin of the parent, in this case .controls */
    *display: inline-block;
    *margin-left: 0;
    *padding-left: 20px;
  }
  // Remove bottom margin on block level help text since that's accounted for on .control-group
  .help-block {
    margin-top: $baseLineHeight * 0.5;
    margin-bottom: 0;
  }
  // Move over buttons in .form-actions to align with .controls
  .form-actions {
    padding-left: 160px;
  }
}
