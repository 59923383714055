// PROGRESS BARS
// -------------
@import "variables.scss"; // Modify this for custom colors, font-sizes, etc
@import "mixins.scss";


// ANIMATIONS
// ----------

// Webkit

// Spec
@keyframes progress-bar-stripes {
  from  { background-position: 0 0; }
  to    { background-position: 40px 0; }
}



// THE BARS
// --------

// Outer container
.progress {
  overflow: hidden;
  height: 18px;
  margin-bottom: 18px;
  @include vertical-gradient(#f5f5f5, #f9f9f9);
  @include box-shadow(inset 0 1px 2px rgba(0,0,0,.1));
  @include border-radius(4px);
}

// Bar of progress
.progress .bar {
  width: 0%;
  height: 18px;
  color: $white;
  font-size: 12px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  @include vertical-gradient(#149bdf, #0480be);
  @include box-shadow(inset 0 -1px 0 rgba(0,0,0,.15));
  @include box-sizing(border-box);
  @include transition(width .6s ease);
}

// Striped bars
.progress-striped .bar {
  @include striped-gradient(#149bdf);
  @include background-size(40px 40px);
}

// Call animation for the active one
.progress.active .bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
     -moz-animation: progress-bar-stripes 2s linear infinite;
          animation: progress-bar-stripes 2s linear infinite;
}



// COLORS
// ------

// Danger (red)
.progress-danger .bar {
  @include vertical-gradient(#ee5f5b, #c43c35);
}
.progress-danger.progress-striped .bar {
  @include striped-gradient(#ee5f5b);
}

// Success (green)
.progress-success .bar {
  @include vertical-gradient(#62c462, #57a957);
}
.progress-success.progress-striped .bar {
  @include striped-gradient(#62c462);
}

// Info (teal)
.progress-info .bar {
  @include vertical-gradient(#5bc0de, #339bb9);
}
.progress-info.progress-striped .bar {
  @include striped-gradient(#5bc0de);
}

// Warning (orange)
.progress-warning .bar {
  @include vertical-gradient(lighten($orange, 15%), $orange);
}
.progress-warning.progress-striped .bar {
  @include striped-gradient(lighten($orange, 15%));
}
