// global.scss
// Global styles to customize Bootstrap for SlideShare
// -----------------------------------------------------

// Core variables and mixins
@import "variables.scss"; // Modify this for custom colors, font-sizes, etc
@import "mixins.scss";

// SPRITES
// Glyphs and icons for buttons, nav, and more
// -------------------------------------------


// ICONS
// -----

// All icons receive the styles of the <i> tag with a base class
// of .i and are then given a unique class to add width, height;
// and background-position. Your resulting HTML will look like
// <i class="icon-inbox"></i>.

// For the on-hover version of the icons (only where available), just add the .icon-hover class:
// <i class="icon-inbox icon-hover"></i>

[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url($spritePath);
  background-position: 14px 14px;
  background-repeat: no-repeat;

  @include ie7-restore-right-whitespace();
}

.icon-logout                    { background-position: -185px  0; }

[class^="sprite-"],
[class*=" sprite-"] {
  display: inline-block;
  vertical-align: text-top;
  background-image: url($spritePath);
  background-repeat: no-repeat;

  @include ie7-restore-right-whitespace();
}

// Flash notification below header
// -------------------------

.flash-msg {
  margin-top: 18px;
}

// Navbar
// -------------------------

.navbar .container {
  width: $gridRowWidth;
}

.navbar-inner {
  @include border-radius(0);
}

$navbarElementsMargin: 15px;

.navbar-search,
.navbar .container > .nav > li > a {
  margin-right: $navbarElementsMargin;

  .fa-search {
    border: 0;
    background: none;
    vertical-align: middle;
    color: inherit;
  }
}

.navbar .nav > li > span > a {
  font-weight: 700;
  margin-right: $navbarElementsMargin - 10;
}

// Styles for quick upload cta
.navbar .nav {
  .placeholder {
    .btn.btn-primary {
      float: left;
      font-weight: 700;
      height: 28px;
    }
    img.quick-upload-loading {
      margin: 0 0 0 7px;
    }
  }
}

.nav .btn {
  @include border-radius(3px);
}

.goProLabel {
  background: $navbarLinkColor;
  $border-radius: 2px;
  @include border-radius($border-radius);
  color: $navbarBackgroundHighlight;
  font-size: 11px;
  padding: 1px 2px;
  text-transform: uppercase;
}
a.goPro:hover .goProLabel {
  background: $navbarLinkColorHover;
  color: $black;
}

.navbar .nav li a.topnav-dropdown-userImage {
  display: inline-block;
  padding: 9px 4px 7px 10px;
  float: left;
  img{border-radius: 3px;
  height: 24px;
  width: 24px;
  margin:0;}
  i { margin-top: 2px; }
}
.navbar .nav li a.topnav-dropdown-carret  {
  display: inline-block;
  padding: 11px 10px 11px 0;
  float: left;
}

.nav .dropdown:hover .dropdown-menu{
    display: block;
}

.navbar .dropdown-menu {
  margin-top: 0;
}

.caret { @include opacity(70); }

.dropdown-menu .metadata {
    color: $brown;
    display: block;
    font-size: 10px;
    line-height: 0.8;
    margin-bottom: 3px;
}
.dropdown-menu a:hover .metadata {
    color: $brownLight;
}

// Tabs
// -------------------------
.nav-tabs > li > a:hover {
  background: $brownLight;
}

// Nav list
// -------------------------
/*.nav-list > li > a {
  background-color: ;
}*/
.nav-list > li > a:hover {
  background-color:  $brownLight ;

}

// Ad styles
// -------------------------
body .banner                                    { position: relative; margin: 10px 0; }
.removeAd                                       { background: #f6f5ef; border: 1px solid #cccbc4; color: #333; height: 13px;
                                                  font-size: 13px; font-weight: 700; line-height: .8;
                                                  padding-left: 1px; position: absolute;
                                                  text-align: center; right: 0; top: 0; width: 13px;
                                                  -webkit-border-radius: 7px; -moz-border-radius: 7px; border-radius: 7px; }
.removeAd:hover                                 { background: #f9f8f2; color: #111; text-decoration: none; }
.banner img                                     { height: auto; max-width: 960px; }
.banner a img,
.banner a:hover img                             { background: transparent; border: 0; }
.banner .removeAd                               { background: #BBB9AA; border: 0; left: 734px; }
.skyScraper .removeAd                           { position: absolute; right: -18px; top: 1px; }
.transcriptAd                                   { position: relative; }
.transcriptAd .removeAd                         { left: 734px; top: -49px; }
.transcriptAdDiv                                { position: relative; top: -50px; } //This line needs re-look
/* Leaderboard (728x90) -- player overlay */
.playerAd                                       { bottom: 37px; position: absolute; width: 728px; }
.playerAd .h-mid-banner-content                 { height: 90px; margin: 0 auto; width: 728px; }
.playerAd a.removeAd                            { right: 5px; top: -20px; }


// Form states and alerts
// -------------------------

// FB Email Rerequest Banner
.fb-email-rerequest-banner {
  a {
    color: $linkColor;
  }
}

// Global FB message asking for opengraph permissions
.global-message-bar-container {
  background: #2c404e;
  .message-bar {
    color: #fff;
    padding: 10px 0;
    position: relative;
    max-width: 940px;
    width: auto;

    p {
      margin: 0;
      padding: 0 5px;
      text-align: center;
      width: 93.5%
    }

    a.li-connect {
      @include vertical-gradient(#F6F6F6, #C1C1C1);
      background-color: #C1C1C1;
      background-repeat: no-repeat;
      color: #000;
      padding: 4px 7px 4px 29px;
      -webkit-box-shadow: inset 0 1px 0 #FFF, 0 1px 1px rgba(49, 49, 49, 0.44);
      box-shadow: inset 0 1px 0 #FFF, 0 1px 1px rgba(49, 49, 49, 0.44);
      border: 1px solid #2C404E;
      @include border-radius(3px);
      position: relative;
      margin-left: 10px;
      text-shadow: 0 1px 0 #E9E9E9;

      span {
        background: url($spritePath) no-repeat;
        background-position: -229px -14px;
        width: 21px;
        font-size: 12px;
        position: absolute;
        display: block;
        height: 21px;
        left: 2px;
        top: 0;
      }

      &:hover {
        background-position: 0 -10px;
        text-decoration: none;
      }

      &:active {
        -webkit-box-shadow: inset 0 0 2px #000;
        box-shadow: inset 0 0 2px #000;
      }
    }

    .close {
      border-radius: 2px;
      border: 1px solid #516B7E;
      color: #516B7E;
      float: right;
      font-size: 12px;
      height: 15px;
      line-height: 15px;
      padding: 0px 4px 1px;
      position: absolute;
      right: 0;
      text-shadow: none;
      top: 10px;
      opacity: 0.8;

      &:hover {
        border: 1px solid #6393A2;
        color: #6393A2;
        text-decoration: none;
      }
    }
  }
}

.global-message-bar-container.android-app-promo-flash {
  background: #336887;

  .close {
    border: 1px solid #4392CF; color: #4392CF;
  }


  .message-bar span {
    #android-banner-txt {
      color: #FFF;
      font-size: 18px;
      font-weight: 100;
      letter-spacing: 1px;
    }
    a:hover {
      text-decoration: none;
    }
    margin: 0;
    position: relative;
    text-align: left;
    top: 1px;
  }

  #android-banner-img {
    margin-left: 20px;

    img {
      width: 120px;
    }
  }

  .message-bar .close {
    border: 1px solid #4392CF;
    color: #4392CF;
    margin-top: 11px;
    @include opacity(60);
  }

  .message-bar .close:hover {
    border: 1px solid #EEE;
    color: #FFF;
  }
}


// Modal Login window

/* linkedin connect */

.connect-options {position: absolute;
width: 304px;
float: left;
border-right: 1px solid #E4E4E4;
height: 258px;
top: 63px;
left: 0;
  .or-divider {
    background: url(/images/modal-sprite.png) -47px -86px no-repeat;
    height: 23px;
    position: absolute;
    right: -12px;
    top: 85px;
    width: 23px;
  }
}

.li-cta  {position: relative;
display: block;
clear: both;
margin: 12px 3px 5px 50px;

   .icon {background: url(https://static02.linkedin.com/scds/common/u/img/sprite/sprite_connect_v13.png) 1px -218px no-repeat;
    cursor: pointer;
    border: 0;
    text-indent: -9999em;
    overflow: hidden;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0px;
    top: 0px;
    display: block;
    width: 34px;
    height: 33px;
    float: right;}

    .title {color: #333;
    cursor: pointer;
    display: block;
    white-space: nowrap;
    float: left;
    margin-left: 1px;
    vertical-align: top;
    overflow: hidden;
    text-align: center;
    height: 25px;
    padding: 6px 12px 0 43px;
    border: 1px solid black;
    border-top-color: #E2E2E2;
    border-right-color: #BFBFBF;
    border-bottom-color: #B9B9B9;
    border-left-color: #E2E2E2;
    border-left: 0;
    text-shadow: white -1px 1px 0;
    line-height: 20px;
    @include border-radius(0 2px 2px 0);
    background-color: #ECECEC;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FEFEFE), color-stop(100%, #ECECEC));
    background-image: -webkit-linear-gradient(top, #FEFEFE 0%, #ECECEC 100%);
      span {font-size: 14px;
      font-family: Arial, sans-serif;
      font-weight: bold;
      font-style: normal;
      display: inline-block;
      background: transparent none;
      vertical-align: baseline;
      height: 18px;
      line-height: 20px;
      float: none;}
    }
}

.FBlogin {
  height: 350px;
  overflow: hidden;

  .close {
    background: transparent;
    border: 0;
    @include opacity(10);
    position: absolute;
    right: 14px;
    top: 7px;
    // Moving the cta to bottom-right temporarily as an experiment to check it's impact on the clicks
    &.bottom-right {
      font-size: 12px;
      font-weight: 400;
      @include opacity(20);
      right: 9px;
      top: 336px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  .spinnerForIframe {
    padding: 40px 0 0 0;
    position: absolute;
    left: 430px;
    text-align: center;
    top: 110px;
  }
  .modal-header {
    border: 0;

    h3.title {
      background: url(/images/modal-sprite.png) no-repeat;
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
      padding-left: 33px;
    }

    .signup-plans-page {
      padding: 77px 21px 0 20px;
    }
  }

  .modal-body {
    padding: 25px 40px 40px;
  }

  .fb-login, .signup-plans-page {
      display: block;
      height: 128px;
      left: 35px;
      padding: 77px 31px 0 10px;
      position: absolute;
      top: 46px;
      width: 220px;

      .btn.btn-success {
        font-weight: 700;
      }
    }

    .fb-login-cta {
      position: absolute;
      top: 33px;
      a.fb_button {
        @include vertical-gradient(#7081a8, #4d5f92);
        @include box-shadow(inset 0 1px 0 #9FACC2);
        background-position: 0 0;
        border-radius: 4px;
        border: 1px solid #2F4364;
        color: white;
        font-weight: 700;
        padding: 10px 25px 11px 60px;
        position: relative;
        text-shadow: 0 -1px 0 #324A6F;

        span.fb-button-icon {
          background: url(/images/modal-sprite.png) -20px -32px no-repeat;
          display: block;
          width: 22px;
          height: 36px;
          position: absolute;
          left: 14px;
          top: 0;
        }

        &:hover {
          cursor: pointer;
          text-decoration: none;
        }

        &:active {
          background-color: #4D5F92;
          background-position: 0 -20px;
          @include box-shadow(inset 0 0 5px #000);
        }
      }

      img.loading {
        position: absolute;
        right: -22px;
        top: 9px;
      }
    }

    .fb_iframe_widget {
      border-radius: 4px;
      @include box-shadow(inset 0 0 5px #CCC);
      border: 1px solid #EBEBEB;
      display: block;
      height: 84px;
      margin: 0;
      padding: 10px 9px;
      position: absolute;
      top: 89px;
      width: 197px;
    }
}
// GRID
// --------------------------------------------------

// Default 940px grid
// -------------------------

.span4.sidebar {
  background: $sidebarColor;
  border-right: $sidebarBorderWidth solid $sidebarBorderColor;
  padding: $sidebarPadding;
  width: ($gridColumnWidth*4)+($gridGutterWidth*3)-($sidebarPadding*2);
  @include border-radius(5px 0 0 5px);
  margin-bottom: $sidebarPadding;
}
.span8.mainContent {
  background: $white;
  margin-bottom: $sidebarPadding;
  margin-left: ($gridGutterWidth)-($gridGutterWidth);
  padding-left: $sidebarPadding;
  padding-right: $sidebarPadding;
  width: ($gridColumnWidth*8)+($gridGutterWidth*7)+($gridGutterWidth)-($sidebarBorderWidth)-($sidebarPadding*2);
  @include border-radius(0 5px 5px 0);
}
.sidebarPanels {
  @include border-radius(5px);
}
.span8.mainContent .page-header {

}

// Thumbnails list
// -------------------------

.thumbnails li {
  background: $sidebarColor;
  font-size: $baseFontSize - 1;
  height: 179px;
  padding: 5px;
  width: 122px;
  @include border-radius(3px);
}
.thumbnail:hover,
.thumbnail:focus {
  background: $brownDarker;
  border-color: $brown;
  @include box-shadow();
}
.thumbnails .thumbnail > a {
  margin-bottom: 4px;
}
.thumbnail > a {
  display: block;
  font-weight: 700;
  margin-bottom: 4px;
  position: relative;
}
.thumbnail > a:hover,
.thumbnail > a:focus {
  text-decoration: none;
}
.thumbnail:hover a,
.thumbnail:focus a {
  color: $brownLightest;
}

.thumbnail a span {
  background: $black;
  border: 1px solid $brownLight;
  display: block;
  height: 90px;
  overflow: hidden;
  margin-bottom: 4px;
  width: 120px;
}
.thumbnail a:hover span {
  border-color: $brownDark;
}
.thumbnail a span.video {
  vertical-align: middle;
}
.thumbnail img {
  width: 120px;
}
.thumbnail span.video img {
  vertical-align: middle;
}

.thumbnail small {
  font-size: $baseFontSize - 2;
  line-height: ($baseLineHeight) - 4;
}

.thumbnail a.slidecast span.slidecastIcon {
  background: #F5F5F5 url(/v3/images/sprite.png?1) no-repeat scroll -206px -63px;
  height: 16px;
  left: 4px;
  padding: 1px;
  position: absolute;
  top: 4px;
  width: 18px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 2px;
}

.thumbnail a.videoslide span.videoslideIcon {
  background: #F5F5F5 url(/v3/images/sprite.png?1) no-repeat scroll -403px -222px;
  height: 16px;
  left: 4px;
  padding: 1px;
  position: absolute;
  top: 4px;
  width: 18px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 2px;
}

// User thumbnails
// -------------------------

.user.thumbnails img {
  background: $white;
  height: 120px;
  max-height: 120px;
  width: 120px;
  margin-bottom: 5px;
  display: block;
}

.thumbnail span.proUserName {
  background: transparent;
  border: 0;
  display: block;
  height: 14px;
  margin-bottom: 4px;
  width: auto;

}

.user.thumbnails small {
  display: block;
  height: 40px;
}


// Fluid grid
// -------------------------

// Typography
// -------------------------
.no-wrap {
  white-space: nowrap;
}

// Footer
// -------------------------

/* Responsive footer */

@media only screen and (max-width:540px) {
  footer {
    .footerWrapper {
      .row {
        .mobile-hide-2 {
          display:none !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 540px) and (max-width:735px) {
  footer {
    .footerWrapper {
      .row {
        .mobile-hide-1 {
          display:none !important;
        }
      }
    }
  }
}

footer {
  background: #eee;
  font-size:12px;
  margin:0 auto;
  max-width:100%;
  .footerWrapper:before {
    border-bottom:1px solid $white;
    border-top: 1px solid $brownLight;
    content: "";
    display: block;
    margin-top: 20px;
  }
  .footerWrapper {
    background:none;
    clear:both;
    color:$grayLight;
    margin: 0 auto;
    max-width: 960px;
    padding:15px 0 20px;
    text-align:left;
    .row {
      .lang {
        height: auto;
        padding: 20px 0 10px;
        text-align: center;
        width: 100%;
        ul {
          display: block;
          margin: 0;
          @include box-shadow(none);
          li {
            display: inline-block;
            a {
              background: none;
              border: none;
              color: #666;
              padding: 4px 10px;
              @include border-radius(2px);
              @include transition(background 0.1s ease-in);
              &:hover  {
                background: #dcdcdc;
                color: #666;
              }
            }
          }
        }
        li.active a {
          background: #666;
          color: $white;
          &:hover  {
            background: #666;
            color: $white;
          }
        }
      }
    }
    .top {
      margin-bottom:12px;
    }
    #smt-lang-selector{display: none;}
    .about-links {
      height: auto;
      margin: 0;
      padding: 10px 0;
      text-align: center;
      ul {
        @include box-shadow(none);
        display: inline-block;
        list-style: none;
        margin: 0;
        li {
          display: inline;
          float:left;
          font-size:12px;
          a {
            background: none;
            border: none;
            color:#666;
            font-size: 12px;
            font-weight: 400;
            line-height: 8px;
            padding: 0 11px;
            text-shadow:0 0 0 $grayDull;
            &:hover  {
              color:#666;
            }
          }
        }
      }
    }
    .bottom {
      text-align: center;
      padding: 10px 0 0 10px;
      a {
        background:url($spritePath);
        display: inline-block;
        height:21px;
        margin-right: 10px;
        text-indent: -9999px;
        width:22px;
      }
      span {
        font-size: 11px;
        padding-left: 25px;
        vertical-align: bottom;
      }
      .fb-link {
        background-position: 0 -207px;
        &:hover {
          background-position: 0 -235px;
        }
      }

      .tw-link {
        background-position: -24px -207px;
        &:hover {
          background-position: -24px -235px;
        }
      }

      .g-link {
        background-position: -50px -207px;
        &:hover {
          background-position: -50px -235px;
        }
      }

      .li-link {
        background-position: -110px -207px;
        &:hover {
          background-position: -110px -235px;
        }
      }

      .rss-link {
        background-position: -135px -207px;
        color: $black;
        margin-right: 0;
        &:hover {
          background-position: -135px -235px;
        }
      }
    }
  }
}

/*  Fancy box ------------------------------------------------------ */
div#fancy_overlay       { display: none; height: 100%; left: 0; position: fixed; top: 0; width: 100%; z-index: 30; }
div#fancy_loading       { background: transparent; cursor: pointer; display: none; height: 40px;
                          overflow: hidden; position: absolute; width: 40px; z-index: 100; }
div#fancy_loading div   { background: transparent url(/images/fancy_progress.png) no-repeat; height: 480px; left: 0;
                          position: absolute; top: 0; width: 40px; }
div#fancy_outer         { background: transparent; display: none; left: 0; margin: 0; padding: 20px 20px 40px 20px; position: absolute;
                          top: 0; z-index: 90; }
div#fancy_inner         { background: #FFF; height:100%; position: relative; width:100%; }
div#fancy_content       { margin: 0; overflow: hidden; padding: 12px; position: absolute; z-index: 100; }
div#fancy_div           { height: 100%; width: 100%; z-index: 100;
                          h1 {
                              color: #222;
                              display: block;
                              font-size: 21px;
                              margin: 0 0 4px 0;
                              text-align: left;
                              width: 542px;
                             }

                          h2 {
                              font-size: 14px;
                             }

                          li {
                              list-style-position: inside;
                              list-style-type: disc;
                             }
                          .remove_ad_cta_popup {
                              float: left;
                              margin-right: 32px;
                             }
                          .ctaFocus {
                              margin: 18px 0;
                             }
                        }
img#fancy_img           { border: 0; height: 100%; left: 0; margin: 0; padding: 0; position: absolute; top: 0; width: 100%; z-index: 100; }
div#fancy_close         { background: #222; border: 2px solid #EEE;
                          color: #FFF; cursor: pointer; display: none;
                          font: 700 12px/1em Verdana, sans-serif;
                          padding: 3px 4px 4px 5px; position: absolute; right: -10px; top: -9px; z-index: 181;
                          border-radius: 12px; -moz-border-radius: 12px; -webkit-border-radius: 12px;
                          box-shadow: 0 1px 4px #333; -moz-box-shadow: 0 1px 4px #333; -webkit-box-shadow: 0 1px 4px #333; }
#fancy_frame            { display: none; height: 100%; position: relative; width: 100%; }
#fancy_ajax             { height: 100%; padding: 12px; overflow: hidden; width: auto; }

a#fancy_left,
a#fancy_right           { background-image: url(data:image/gif;base64,9999); bottom: 0; cursor: pointer; display: none; height: 100%;
                          outline: none; overflow: hidden; position: absolute; width: 35%; z-index: 111; }
a#fancy_left            { left: 0; }
a#fancy_right           { right: 0; }
span.fancy_ico          { cursor: pointer; display: block; height: 30px; margin-top: -15px; position: absolute; top: 50%; width: 30px; z-index: 112; }

a#fancy_left:hover,
a#fancy_right:hover     { background-color: transparent; visibility: visible; }
a#fancy_left:hover span { left: 20px; }
a#fancy_right:hover span{ right: 20px; }

#fancy_bigIframe        { background: transparent; height: 100%; left: 0; position: absolute; top: 0; width: 100%; }

div#fancy_bg            { border: 0; height: 100%; left: 0; margin: 0; padding: 0; position: absolute; top: 0; width: 100%; z-index: 70; }
div.fancy_bg            { border: 0; display: block; margin: 0; padding: 0; position: absolute; z-index: 70; }

div#fancy_title         { display: none; position: absolute; z-index: 100; }
div#fancy_title div     { color: #FFF; font: bold 12px Arial; padding-bottom: 3px; white-space: nowrap; }
div#fancy_title table   { margin: 0 auto; }
div#fancy_title table td{ padding: 0; vertical-align: middle; }


/* ---------- Feedback button ----------------------------------- */

.get-feedback {
  background-color: $white;
  background: url(/images/btn-feedback.png) 0 0 no-repeat;
  border: 0;
  cursor: pointer;
  height: 123px;
  margin-top: -61px;
  position: fixed;
  right: 0;
  top: 50%;
  width: 33px;

  &:hover {
    width: 35px;
  }
}

.feedbackTab-search  {
  position: fixed;
  left: 0;
  top: 428px;
  margin-top: -96px;
  display: block;
  height: 145px;
  width: 29px;
  background: url(/images/feedback-tab-left.png) 0 0 no-repeat;
  cursor: pointer;
  &:hover  {
  width: 28px;
  @include opacity(95);
  }
}


/* ---------- Embed Error ---------------------------------- */

.embed-error {
  @include radial-gradient1($brownLightest, $brownLight);
  @include size(250px, 350px);
  margin: -20px 20px -20px -20px;
  padding: 12px;
  text-align: center;
  position: relative;
  .errorCode {
    color: $grayDarker;
    font-size: 24px;
    line-height: 0;
    margin: 20% auto 8%;
    text-align: center;
  }
  hr {
    border: 0;
    border-top: 1px solid $brownLight;
    border-bottom: 1px solid #F7F7F7;
    margin: 10% auto 6%;
    width: 25%;
  }
  .explanation h1 {
    color: $grayDark;
    font-size: 14px;
  }
}
/* -------------------------------------------------------------- */

.pagination {
  height: 30px * 2;
  margin: 15px 0;
  text-align: center;
  a {
    background: $white;
    border: 1px solid #c7c7c7;
    color: #6c6c6c;
    line-height: 28px;
  }
  a:hover,
  li.active a  {
    color: $linkColor;
    background-color: #FCFDFF;
  }
  .disabled span,
  .disabled a,
  .disabled a:hover {
    color: #c7c7c7;
    background-color: #fff;
  }
  .prev {
    &.disabled .arrow {
      border-color: transparent #c7c7c7 transparent transparent;
    }
    .arrow {
      border-style: solid;
      border-color: transparent #6c6c6c transparent transparent;
      border-width: 5px 8.7px 5px 0;
      display: inline-block;
      height: 0px;
      width: 0px;
    }
  }
  .next {
    &.disabled .arrow {
      border-color: transparent transparent transparent #c7c7c7;
    }
    .arrow {
      border-style: solid;
      border-color: transparent transparent transparent #6c6c6c;
      border-width: 5px 0 5px 8.7px;
      display: inline-block;
      height: 0px;
      width: 0px;
    }
  }
}

.page-header p             { margin: 5px 0 0 0; }
.page-body {
  background: $white;
  border: 1px solid $brownLight;
  @include border-radius(5px);
  margin-bottom: 20px;
  position: relative;
  margin-top: -22px;
}

.float-right { float: right; }
.float-left { float: left; }

.table-container {
  background: $white;
  border: 1px solid $brownLight;
  @include border-radius(5px);
  padding-top: 20px;
  position: relative;
  margin-bottom: 20px;
}

.container-box {
  background: $white;
  border: 1px solid $brownLight;
  @include border-radius(5px);
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}

img.logo-premium {
  display: block;
  margin: 15px auto;
  width: 120px;
  height: auto;
}

.premium-heading {
  margin-bottom: 20px;
  text-align: center;
  .lead  {
  padding-top: 5px;
  }
}

// Minimum height reset
// ----------
.min-height {
  height:auto !important; /* real browsers */
  height:100%; /* IE6: treaded as min-height*/

  min-height:90%; /* real browsers */
}

.datepicker {
  background-color: $white;
  border: 1px solid $brownLight;
  @include border-radius(4px);
  @include box-shadow();
  display: none;
  position: absolute;
  z-index: 900;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: $sidebarPadding;
  padding-bottom: 4px;
  width: 218px;
}
.datepicker .nav {
  font-weight: 700;
  width: 100%;
  padding: 4px 0;
  background-color: #f5f5f5;
  color: $grayLight;
  border-bottom: 1px solid $brownLight;
  @include box-shadow(inset 0 1px 0 $white);
  zoom: 1;
  @include border-radius(4px 4px 0 0);
}
.datepicker .nav:before, .datepicker .nav:after {
  display: table;
  content: "";
  zoom: 1;
  *display: inline;
}
.datepicker .nav:after {
  clear: both;
}
.datepicker .nav span {
  display: block;
  float: left;
  text-align: center;
  height: 28px;
  line-height: 28px;
  position: relative;
}
.datepicker .nav .bg {
  width: 100%;
  background-color: $yellowLight;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
  @include border-radius(4px);
}
.datepicker .nav .fg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.datepicker .button {
  cursor: pointer;
  padding: 0 4px;
  @include border-radius(4px);
}
.datepicker .button:hover {
  background-color: $brownDark;
  color: $white;
}
.datepicker .months {
  float: left;
  margin-left: 4px;
}
.datepicker .months .name {
  width: 72px;
  padding: 0;
}
.datepicker .years {
  float: right;
  margin-right: 4px;
}
.datepicker .years .name {
  width: 36px;
  padding: 0;
}
.datepicker .dow, .datepicker .days div {
  float: left;
  width: 30px;
  line-height: 25px;
  text-align: center;
}
.datepicker .dow {
  font-weight: 700;
  color: #808080;
}
.datepicker .calendar {
  padding: 4px;
}
.datepicker .days div {
  cursor: pointer;
  @include border-radius(4px);
}
.datepicker .days div:hover {
  background-color: $brownDarker;
  color: $white;
}
.datepicker .overlap {
  color: #bfbfbf;
}
.datepicker .today {
  background-color: $yellowLight;
}
.datepicker .selected {
  background-color: $brownLight;
  color: $white;
}
.modal {
  width: 600px;
  left: 49%;
}

/* Language selector CSS */
/* top level  */
ul.smt-menu {
  position: relative;
  list-style: none !important;
  text-indent: none;
  padding: 0;
  width: 175px;
  overflow: visible;
  display: block;
  float: left;
  margin: 0 10px 0 0;
}

ul.smt-menu.smt-autoPlace {
  z-index: 99999;
}

/* no focus dotted line  */
ul.smt-menu :focus {
}

/* container of menu items */
ul.smt-menu ul {
  display: none;
  position: absolute;
  list-style: none !important;
  text-indent: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #F6F5EF;
  border: 1px solid #DDDCD7;
}

/* auto placed menus need position static */
ul.smt-autoPlace ul {
  position: static !important;
}

/* list items (includes trigger) */
ul.smt-menu li {
  display: block;
}

/* styles trigger link */
ul.smt-menu a.smt-trigger-link {
  display: block;
  text-decoration: none;
  font: bold 12px arial;
  color: #fff;
  cursor: pointer;
  padding: 2px 10px 2px 10px !important;
  background: none;
}

ul.smt-menu.smt-botRight a.smt-trigger-link {
  background-image: url('/images/smartling-arrow-up.png');
}

/* styles item link tags */
a.smt-link {
  color: #666;
  display: block;
  font-size: 12px;
  line-height: 12px;
  text-align: left;
  text-decoration: none !important;
  word-wrap: break-word;
}

/* hover state for menu items */
ul.smt-menu li li a:hover {
  color: #fff !important;
  background-color: #0077aa;
}

/* bootstrap style icons for social networks, sendtracker and edit/my uploads */
.iconTemp-tracker {
  background: url(/images/icons-tracker-social.png) 1px 1px no-repeat;
}

.iconTemp-facebook {
  background: url(/images/icons-tracker-social.png) 3px -16px no-repeat;
}

.iconTemp-twitter {
  background: url(/images/icons-tracker-social.png) 3px -33px no-repeat;
}

.iconTemp-linkedin {
  background: url(/images/icons-tracker-social.png) 2px -50px no-repeat;
}

.iconTemp-capture-leads {
  background: url(/images/icons-tracker-social.png) 2px -67px no-repeat;
}

.iconTemp-link {
  background: url(/images/analytics-mini.png) no-repeat;
  background-position: 2px -59px !important;
}

.iconTemp-eye {
  background: url("/images/icons-tracker-social.png") no-repeat 0 -103px;
  height: 14px;
  width: 14px;
}

.iconTemp-public {
  background: url("/images/icons-tracker-social.png") no-repeat 0 -85px;
  height: 14px;
  width: 14px;
}

// Like action on thumbnails
// -------------------------

// Sidebar on Slideview
.relatedContent, .moreContent {
  li {
    overflow: hidden;
    position: relative;
    &:hover .favorite-cta {
      display: block;
      @include opacity(100);
    }
  }
}
// Like cta styles
.favorite-cta.j-favorited, .favorite-cta.j-favorite {
  background: #000 url($spritePath) no-repeat 5px -167px;
  @include border-radius(3px);
  color: #CCC !important;
  font-size: 12px;
  font-weight: 400;
  left: 11px;
  @include opacity(0);
  padding: 0 5px 0 20px;
  position: absolute;
  bottom: 8px;
  line-height: 16px;
  width: auto;
  @include transition(opacity 0.1s ease-in);

  &.j-favorited {
    background-position: 5px -187px;
  }

  &:hover {
    background-color: #000;
    @include box-shadow(0 0px 3px #E2E2E2);
  }
}

.mobile-version {
    position: fixed;
    background: #297ECC;
    color: #fff;
    top: 0;
    display:block;
    width: 100%;
    padding: 20px 0;
    font-size: 14px;
    text-align: center;
    z-index: 9999;
}
.mobile-version-container{
  height:54px;
}

// Global Tooltip formatting
// -------------------------
.tooltip-inner {
  text-align: left;
  padding: 5px 8px;
}

.tooltip.in {
  @include opacity(90);
}

// Static sidenav  about and developer pages
// ------------------------------------------
.static-sidenav, .span3.static-sidenav  {
  padding-top: 5px;
  margin-left: 0;
}

.static-sidenav ul li a.selected {
  background-color: $white;
  color: $grayDark;
}

.static-sidenav .nav-tabs.nav-stacked > li > a {
  border: 1px solid $brownLight;
}

// FLAT BUTTONS
// ------------

.flat-button {
  border-radius: 5px;
  background: #666;
  color: #fff;
  padding: 12px;
  font-size: 14px;
  margin: 2px;
  line-height: normal;
  text-align: center;
  display: inline-block;
  border: 1px solid;
  border-color: #666;
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  -ms-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
}

.flat-button:hover {
  color: #fff;
  background: #666;
}

.flat-button-with-icon {
  display: inline-flex;
  padding: 4px;

  .flat-button-icon {
    float: left;
    width: 26px;
    height: 26px;
    background-size: contain;
    margin: auto 9px;
  }

  .flat-button-text {
    float: left;
    padding: 8px 12px;
    font-size: 19px;
    border-left: 1px solid #4B9BD4;
    font-weight: 100;
    letter-spacing: 0.05em;
  }
}

.flat-button-orange {
  border-color: #E98325;
  background: #E98325;
}

.flat-button-orange:hover {
  background: #E98325;
}

.flat-button-blue {
  border-color: #277ab7;
  background: #277ab7;
}

.flat-button-blue:hover {
  background: #277ab7;
}

.flat-button-li {
  .flat-button-icon {
    background-image: url('/images/LinkedIn-logo-only-75x75.png');
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.flat-button-disabled {
  cursor: default;
}

.add-to-profile-section {
  margin: 0 0 23px 0;

  padding-top: 18px;
  width: 100%;
}

.add-to-profile-text {
  float: left;
  margin-right: 21px;
  padding-right: -15px;
  font-weight: bold;
  margin-left: -13px;
  margin-top: 6px;
  margin-bottom: 0;
}

.add-to-li-profile {
  height: 23px;
  padding-top: 5px;
  margin-top: 0px;
  cursor: pointer;
  width: 176px;
  border-radius: 2px;
  background: #007BB6;
  margin-bottom: 23px;
  float: left;
}

.treasury {
  .flat-button-icon {
    height: 20px;
    width: 18px;
    margin: auto 7px 3px 5px;
  }
  .flat-button-text {
    border-left: 1px solid #4B9BD4;
    float: left;
    font-size: 15px;
    font-weight: 100;
    letter-spacing: 0.05em;
    padding: 2px 12px;
    text-shadow: none;
    color: #ffffff;
  }

}


/* change password style */
.change-password{
  margin-bottom: 30%;
  padding: 18px 30px;
  background: #FFF;
  margin-left: 0;
  h2{
    font-weight: 200;
    font-size: 19px;
    margin-bottom: 24px;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 0px;
  }
  label{
    width: 178px;
    float: left;
  }
  .btn{
    display: block;
    margin: 20px 0 0 177px;
  }
}
.forgot-password{
  margin-bottom: 30%;
  padding: 18px 30px;
  background: #FFF;
  margin-left: 0;
  h2{
    font-weight: 200;
    font-size: 19px;
    padding-bottom: 0px;
  }
  p{
    color: #888;
    margin-bottom: 24px;
    font-weight: 200;
  }
  label{margin-bottom: 6px,}
  .btn{margin:0 7px,}
}
a.pro-bar-info {
  color: #9cd4ec;
}

/* User Suspended Banner */
.user-suspended-banner {
  margin-bottom: 0;
  text-align: center;
  color: $black;
}

.copy-in-aria-label::before {
  content: attr(aria-label);
}
