// Scaffolding
// Basic and global styles for generating a grid system, structural layout, and page templates
// -------------------------------------------------------------------------------------------
@import "variables.scss"; // Modify this for custom colors, font-sizes, etc
@import "mixins.scss";


// Minimum height reset
// ----------
html, body {
height: 100%;
}


// Body reset
// ----------

body {
  margin: 0;
  font-family: $baseFontFamily;
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: $textColor;
  background-color: $bodyBackground;
}


// Links
// -----

a {
  color: $linkColor;
  text-decoration: none;
}
a:hover {
  color: $linkColorHover;
  text-decoration: underline;
}
