// TOOLTIP
// ------=
@import "variables.scss"; // Modify this for custom colors, font-sizes, etc
@import "mixins.scss";

.tooltip {
  position: absolute;
  z-index: $zindexTooltip;
  display: block;
  visibility: visible;
  padding: 5px;
  font-size: 11px;
  @include opacity(0);
  &.in     { @include opacity(80); }
  &.top    { margin-top:  -2px; }
  &.right  { margin-left:  2px; }
  &.bottom { margin-top:   2px; }
  &.left   { margin-left: -2px; }
  &.top .tooltip-arrow    { @include popover-top(); }
  &.left .tooltip-arrow   { @include popover-left(); }
  &.bottom .tooltip-arrow { @include popover-bottom(); }
  &.right .tooltip-arrow  { @include popover-right(); }
}
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: $white;
  text-align: center;
  text-decoration: none;
  background-color: $black;
  @include border-radius(4px);
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
}
