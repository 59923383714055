// BREADCRUMBS
// -----------
@import "mixins.scss";
@import "variables.scss";

.breadcrumb {
  padding: 3px 0;
  margin: 0 0 7px;
  list-style: none;
  font-size:12px;
  li {
    display: inline-block;
    @include ie7-inline-block();
    text-shadow: 0 1px 0 $white;
  }
  .divider {
    color: $brown;
    padding: 0 5px;
  }
  .active a {
    color: $grayDark;
  }
}
