// Core variables and mixins
@import "variables.scss"; // Modify this for custom colors, font-sizes, etc
@import "mixins.scss";

$mobileMenuBaseColor: #333;
$mobileMenuTextColor: #D1D1D1;
$dropdownColor: #4D4D4D;
$navFontColor: #D7D7D7;

// NAVBAR (FIXED AND STATIC)
// -------------------------

// COMMON STYLES
// -------------

// Gradient is applied to it's own element because overflow visible is not honored by IE when filter is present
.navbar-inner {
  padding-left:  20px;
  padding-right: 20px;
  @include vertical-gradient($navbarBackgroundHighlight, $navbarBackground);
  @include border-radius(0);
}

// Navbar button for toggling navbar items in responsive layouts
.btn-navbar {
  display: none;
  float: right;
  padding: 7px 10px;
  margin-left: 5px;
  margin-right: 5px;
  @include buttonBackground($navbarBackgroundHighlight, $navbarBackground);
  $shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
  @include box-shadow($shadow);
}
.btn-navbar .icon-bar {
  display: block;
  width: 18px;
  height: 2px;
  background-color: #f5f5f5;
  @include border-radius(1px);
  @include box-shadow(0 1px 0 rgba(0,0,0,.25));
}
.btn-navbar .icon-bar + .icon-bar {
  margin-top: 3px;
}
// Override the default collapsed state
.nav-collapse.collapse {
  height: auto;
}


// Brand, links, text, and buttons
.navbar {
  // Fix for IE7's bad z-indexing so dropdowns don't appear below content that follows the navbar
  *position: relative;
  *z-index: 2;

  overflow: visible;
  margin-bottom: $baseLineHeight;

  .container {
    margin: 0 auto;
    width: auto;
  }

  color: $navbarText;
  // Hover and active states
  .brand:hover {
    text-decoration: none;
  }

  // Website or project name
  .brand {
    float: left;
    display: block;
    padding: 8px 20px 12px;
    margin-left: -20px; // negative indent to left-align the text down the page
    font-size: 20px;
    font-weight: 200;
    line-height: 1;
    color: $white;
  }
  // Plain text in topbar
  .navbar-text {
    margin-bottom: 0;
    line-height: $navbarHeight;
  }
  // Buttons in navbar
  .btn,
  .btn-group {
    @include navbarVerticalAlign(30px); // Vertically center in navbar
  }
  .btn-group .btn {
    margin-top: 0; // then undo the margin here so we don't accidentally double it
  }
}

// Navbar forms
.navbar-form {
  margin-bottom: 0; // remove default bottom margin
  @include clearfix();
  input,
  select,
  .radio,
  .checkbox {
    @include navbarVerticalAlign(30px); // Vertically center in navbar
  }
  input,
  select {
    display: inline-block;
    margin-bottom: 0;
  }
  input[type="image"],
  input[type="checkbox"],
  input[type="radio"] {
    margin-top: 3px;
  }
  .input-append,
  .input-prepend {
    margin-top: 6px;
    white-space: nowrap; // preven two  items from separating within a .navbar-form that has .pull-left
    input {
      margin-top: 0; // remove the margin on top since it's on the parent
    }
  }
}

// Navbar search
.navbar-search {
  @include navbarVerticalAlign(28px); // Vertically center in navbar
  float: left;
  margin-bottom: 0;
  position: relative;

  .search-query {
    $shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0px rgba(255,255,255,.15);
    @include box-shadow($shadow);
    @include sans-serif(13px, normal, 1);
    @include transition(none);
    background-color: $navbarSearchBackground;
    border: 1px solid $navbarSearchBorder;
    color: $white;
    padding: 6px 10px;

    // Placeholder text gets special styles; can't be a grouped selector
    &:-moz-placeholder {
      color: $navbarSearchPlaceholderColor;
    }
    &::-webkit-input-placeholder {
      color: $navbarSearchPlaceholderColor;
    }

    // Focus states (we use .focused since IE7-8 and down doesn't support :focus)
    &:focus,
    &.focused {
      color: $grayDark;
      background-color: $navbarSearchBackgroundFocus;
      border: 0;
      outline: 0;
    }
  }

  button.fa-search {
    background: none;
    border: 0;
    color: inherit;
  }
}

.navbar-search,
.navbar .nav > li > span > a {
  @include navbarVerticalAlign(30px);
}

$navbar-search-width: 195px;

.navbar-search .search-query {
  background-color: darken($grayLight, 10%);
  @include border-radius(5px);
  color: $black;
  width: $navbar-search-width;
  $transition: width .2s ease, background .2s ease;
  @include transition($transition);
  margin-right: -35px;
}

.navbar-search .search-query:hover {
  background-color: $navbarLinkColor;
  color: $navbarBackgroundHighlight;
}

.navbar-search .iconTemp-search {
  background-color: transparent;
  border: 0;
  color: transparent;
  position: absolute;
  right: 10px;
  top: 7px;
  width: 14px;
}

// FIXED NAVBAR
// ------------

// Shared (top/bottom) styles
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: $zindexFixedNavbar;
  margin-bottom: 0; // remove 18px margin for static navbar
}
.navbar-fixed-top .navbar-inner,
.navbar-fixed-bottom .navbar-inner {
  padding-left:  0;
  padding-right: 0;
  @include border-radius(0);
}

.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
  // #grid > .core > .span($gridColumns);
}

// Fixed to top
.navbar-fixed-top {
  top: 0;
}

// Fixed to bottom
.navbar-fixed-bottom {
  bottom: 0;
}

.nav {
  #browse {
    margin-top: 11px;
  }
}

#login_link {
  margin-top: 7px;
  margin-right: 8px;

  .create-icn {
    @include border-radius(3px);
    margin: 0 0 0 5px;
    padding: 7px 12px 2px;
    position: relative;
    top: -2px;
    z-index: 9999;

    img {
      height: 16px;
      vertical-align: top;
      width: 18px;
    }
  }

  #upload-link {
    @include border-radius(3px);
    font-weight: 300;
    line-height: 24px;
    margin-top: -2px;
    z-index: 9999;
  }
}

// NAVIGATION
// ----------

.navbar .nav {
  position: relative;
  left: 0;
  display: block;
  float: left;
  margin: 0 10px 0 0;

  .fa-2x {
    font-size: 26px;
  }
}
.navbar .nav.pull-right {
  float: right; // redeclare due to specificity
}
.navbar .nav > li {
  display: block;
  float: left;
}

// Links
.navbar .nav > li > a {
  float: none;
  line-height: 19px;
  color: $navbarLinkColor;
  text-decoration: none;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25);
}
// Hover
.navbar .nav > li > a:hover {
  background-color: $navbarLinkBackgroundHover; // "transparent" is default to differentiate :hover from .active
  color: $navbarLinkColorHover;
  text-decoration: none;
}

// Active nav items
.navbar .nav .active > a,
.navbar .nav .active > a:hover {
  color: $navbarLinkColorActive;
  text-decoration: none;
  background-color: $navbarLinkBackgroundActive;
}

// Dividers (basically a vertical hr)
.navbar .divider-vertical {
  height: $navbarHeight;
  width: 1px;
  margin: 0 9px;
  overflow: hidden;
  background-color: $navbarBackground;
  border-right: 1px solid $navbarBackgroundHighlight;
}

// Secondary (floated right) nav in topbar
.navbar .nav.pull-right {
  margin-left: 10px;
  margin-right: 0;
}

.sub-nav-cat-divider {
  border-right: solid 1px gray;
  display: inline;
  height: 1.21429rem;
  margin-right: 1.16667rem;
  width: 0;
}

// LiL-specific Elements in Sub Nav
.sub-nav-link.lil-link {
  font-weight: 400;
}

.sub-nav-lil {
  color: #D7D7D7;
  font-size: 13px;
  line-height: 29px;
}

// Dropdown menus
// --------------

// Menu position and menu carets
.navbar .dropdown-menu {
  width: 180px;
  top: 36px;
  padding: 0;
  background-color: $dropdownColor;
  color: #E6E6E6;
  @include border-radius(0);

  &:before {
    border-bottom: 7px solid $dropdownColor;
    border-left:   7px solid transparent;
    border-right:  7px solid transparent;
    content: '';
    display: inline-block;
    left: auto;
    position: absolute;
    top: -7px;
    right: 3px;
  }

  &:after {
    border-bottom: 6px solid $dropdownColor;
    border-left:   6px solid transparent;
    border-right:  6px solid transparent;
    content: '';
    display: inline-block;
    left: auto;
    position: absolute;
    top: -6px;
    right: 3px;
  }

  li {
    padding: 0;
    text-align: center;

    a {
        color: $navFontColor;
        font-size: 14px;
        line-height: 49px;

        &:hover {
          background-color: #6A6B6C;
        }
      }

    &.divider {
      border-color: #6A6B6C;
      height: 0;
      margin: 0;
      padding: 0;
    }
  }
}

.navbar {
  .dropdown-menu.creators-hub-dropdown {
    top: 24px;
    &:before, &:after {
      left: 3px;
      right: auto;
    }
    li a {
      line-height: 32px;
    }
  }
  .sub-nav-cats .dropdown:hover {
    .dropdown-menu.creators-hub-dropdown {
      display: block;
    }
  }
}


#explorer {
  width: 220px;
  ul {
    float: left;
    list-style: none;
    margin: 0;
    width: 50%;
  }
}

// Dropdown toggle caret
.navbar .nav .dropdown-toggle .caret,
.navbar .nav .dropdown-toggle-caret .caret,
.navbar .nav .open.dropdown .caret {
  border-top-color: $white;
  border-bottom-color: $white;
}
.navbar .nav .active .caret {
  @include opacity(100);
}

// Remove background color from open dropdown
.navbar .nav .open > .dropdown-toggle,
.navbar .nav .active > .dropdown-toggle,
.navbar .nav .open.active > .dropdown-toggle {
  background-color: transparent;
}

// Dropdown link on hover
.navbar .nav .active > .dropdown-toggle:hover {
  color: $white;
}

// New navbar

body #main_navbar {
  padding-bottom: 83px;
  background-color: #2d2d2d;

  & > .navbar {
    margin-bottom: 0;
    position: fixed;
    -webkit-transform: translate3d(0, 0, 0);
    top: 0;
    left: 0;
    right: 0;
    z-index: 1010;
    opacity: 0.97;
  }
}

.navbar.new_navbar {
  height: 0;

  .navbar-inner {
    background: #2d2d2d;
    height: 54px;

    .container {
      height: 0;
      padding-top: 6px;
      max-width: 965px;
      width: 100%;

      #login_link {
        button {
          background: none;
          border: none;
          @include border-radius(2px);
          margin-top: -2px;
          padding: 0 0 10px 0;

          .fa {
            color: $navFontColor;
            padding-top: 2.5px;
            width: 21px;
          }

          img {
            height: 30px;
            width: 30px;
          }
        }
      }
    }

    .logo-wrapper {
      float: left;
      overflow: auto;

      a {
        color: white;
        display: inline-block;
        font-size: 17px;
        font-weight: bold;
        line-height: 39px;
        -webkit-font-smoothing: antialiased;

        img {
          margin: -1px 4px 0 0;
          height: 36px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .sub-navbar {
    background-color: #4D4D4D;
    box-sizing: border-box;
    padding: 0 20px;
    position: absolute;
    width: 100%;
    z-index: 0;

    .container {
      height: 29px;
      max-width: 965px;
      position: relative;
      width: auto;

      .sub-nav-cats {
        left: 0;
        list-style: none;
        margin: 0;
        position: absolute;

        &.right {
          left: auto;
          right: 0;
          .sub-nav-cat {
            margin-right: 16px;
          }
        }

        .sub-nav-cat {
          display: inline-block;
          margin-right: 16px;

          .sub-nav-link {
            color: #D7D7D7;
            font-weight: 400;
            line-height: 29px;

            &:hover {
              color: #EEE;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .nav_divider {
    color: #4d4d4d;
    font-size: 14px;
    margin-left: 13px;
    padding: 11px 0;

    &:after {
      content: '|';
    }
  }

  .navbar-search, .container .nav > li {
    margin-left: 15px;
    margin-right:  0;

    &.dropdown {
      margin-left: -15px;
      padding-left: 30px;
      padding-bottom: 20px;
    }
  }

  .navbar-search, .container .nav > li > a {
    margin-right: 0;
    line-height: 20px;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.05em;
  }

  .navbar-search, .navbar .nav > li > span > a {
    margin-top: 6px;
  }

  .container > .nav > li > a {
    margin-right: 0;
  }

  .nav > li > a {
    color: $navFontColor;
  }

  .nav > li > span > a {
    font-weight: 700;
    margin-right: 0;
  }

  .nav li a.topnav-dropdown-userImage {
    padding: 8px 0;

    img {
      @include border-radius(2px);
      height: 26px;
      width: 26px;
    }
  }

  .nav > li > span > a.btn {
    -webkit-box-shadow: none;
    background-color: #AF631D;
    background-image: none;
    border: 1px solid #E98325;
    box-shadow: none;
    font-weight: 400;
    letter-spacing: 0.05em;
    line-height: 21px;
    margin-top: 8px;
    padding: 3px 14px;
    text-shadow: none;
    @include transition(background-color 300ms ease-out);

    &:hover,
    &:focus {
      background-color: #E98325;
    }
  }

  .nav li a.topnav-dropdown-carret {
    padding: 11px 0 11px 4px;
  }

  .navbar-search {

    .search-query {
      background: #4d4d4d;
      @include border-radius(2px);
      border: none;
      box-shadow: none;
      color: $navFontColor;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.05em;
      line-height: 20px;
      margin-right: -35px;
      outline: none;
      padding-right: 35px;

      &::-webkit-input-placeholder {
        color: $navFontColor;
      }
      &:-moz-placeholder {
        color: $navFontColor;
      }
      &::-moz-placeholder {
        color: $navFontColor;
      }
      &:-ms-input-placeholder {
        color: $navFontColor;
      }

      &:focus,
      &.focused {
        color: $navFontColor;
        background-color: #4d4d4d;
        border: none;
        outline: none;
      }
    }
  }

  .yes-notification .count {
    padding: 2px 4px;
    position: inherit;
    display: inline;
    position: inherit;
    left: 0;
    top: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background: #e04c4c;
  }

  // for slideview...
  a.menu:after, .dropdown-toggle:after {
    content: '';
    display: none;
  }

   // Upload + Create CTA
  .btn.btn-primary.upload-with-create {
    @include border-radius(3px 0 0 3px);
    display: inline-block;
    margin-right: -2px;
    padding: 4px 14px;
  }

  #create-link.btn-create {
    @include border-radius(0 3px 3px 0);
    display: inline-block;
    padding: 2px 7px 3px;

    .icon-create {
      background: url('/images/icon-create.png') no-repeat 0 0;
      height: 20px;
      width: 20px;
    }
  }
}

// TOS Update Banner
.tos-update-banner {
  background-color: #BDDFDF;
  height: auto;
  width: auto;

  .tos-close {
    background: none;
    border: 0;
    color: #323B3A;
    float: right;
    font-size: 16px;
    padding: 3px 15px 0 0;
    text-decoration: none;
  }

  .text-div {
    .desktop-text {
      padding: 10px;
    }

    .mobile-text {
      line-height: 15px;
      margin-left: 8px;
      padding: 7px;

      a {
        color: #0077aa;
      }
    }

    a {
      font-weight: bold;
    }

    p {
      color: #323B3A;
      font-size: 15px;
      font-weight: bold;
      line-height: 18px;
      margin: 0;
      text-align: center;
    }
  }
}

/* MOBILE NAVBAR */

#mobile-search-bar {
  overflow: hidden;
  display: none;
  background: #dedede;
  border: 1px solid #ccc;

  &.open {
    display: block;
  }

  #mobile-search-form {
    width: 100%;
  }

  .navbar-search {
    margin: 0;
  }

  .search-form-inner-container {
    margin: 10px;
    position: relative;
  }

  .search-field-wrapper {
    text-align: left;
    width: 100%;

    .search-field {
      padding: 9px 35px 5px 10px;
      width: 100%;
      height: auto;
      font-size: 17px;
      font-weight: 300;
      box-sizing: border-box;
      margin: 0;
      @include border-radius(0);
      box-shadow: none;
    }
  }

  .search-submit {
    display: block;
    height: 35px;
    position: absolute;
    right: 1px;
    top: 1px;
    width: 35px;
    z-index: 2;

    .btn {
      background: url("/images/mobile/icon-search-black.png") no-repeat 8px 50%;
      border: 0;
      box-shadow: none;
      height: 35px;
      margin: 0;
      width: 35px;
    }
  }
}

#main-content-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: none;
  z-index: 1005;
  cursor: pointer;
}

/** Hiding Upload and create in tablet and below **/
@media only screen and (max-width: 960px) {
  .navbar-inner {
    #upload-link {
      display: none !important;
    }

    .create-icn {
      display: none !important;
    }
  }
  .sub-navbar .sub-nav-cats.right {
    display: none !important;
  }
}

@media screen and (min-width: 650px) {
  .nav-desktop-show {
    display: block!important;
  }

  .nav-desktop-hide {
    display: none!important;
  }

  .nav-mobile-show {
    display: none!important;
  }
}

@media screen and (max-width: 650px) {
  body #main_navbar {
    padding-bottom: 50px;

    .navbar-inner {
      text-align: center;
      height: 50px;
      padding: 0;

      .container {
        padding: 0;
        height: 100%;

        ul {
          margin: 0;
          width: 100%;

          .mobile-nav-icon {
            color: white;
            margin: 8px 0 0 0;
            width: 25%;

            .mobile-nav-label {
              font-size: 11px;
              font-weight: normal;
            }

            a {
              color: white;
              display: block;
              width: 100%;
            }

            ul {
              list-style-type: none;
            }

            .mobile_nav_logo {
              margin-bottom: -6px;
              width: 21px;
            }

            #mobile_menu_login {
              color: inherit;
            }

            .fa-search.fa-2x {
              font-size: 1.85em;
            }

            .fa-2x {
              font-size: 2em;

              &.fa-user {
                margin: -1px 0 -2px 0;
              }
            }

            li.dropdown {
              background: none;
              border: 0;
              color: inherit;
              height: 20px;
              padding: 0;
              margin: 0;

              button {
                background: none;
                border: none;
                color: inherit;
                margin-top: -2px;

                img {
                  @include border-radius(2px);
                  height: 22px;
                  width: 22px;
                }
              }

              .dropdown-menu {
                width: 180px;
                left: initial;
                top: 42px;

                &:before {
                  right: 10.5vw;
                }

                &:after {
                  right: 10.5vw;
                }

                .divider {
                  border-bottom: 1px solid #626262;
                }
              }
            }
          }
        }
      }
    }
  }

  .nav-mobile-show {
    display: block!important;
  }

  .nav-desktop-show {
    display: none!important;
  }

  .nav-mobile-hide {
    display: none!important;
  }

  body.search-bar-opened {
    #main-content-overlay {
      display: block;
    }
  }
}

@media only screen and (max-width: 750px) {
  #main_navbar .sub-navbar .sub-nav-cats .sub-nav-cat {
    margin-right: 25px;
  }
}

.searchSuggestionsTop {
  background: $dropdownColor;
  list-style: none;
  margin: 0;
  position: absolute;
  top: 42px;
  width: 300px;
  z-index: 1000;
  @include box-shadow();

  li {
    border-bottom: 1px solid #6A6B6C;
    color: inherit;
    line-height: 49px;
    padding: 3px 15px;
    text-align: left;

    a {
      color: $dropdownLinkColor;
    }

    &:hover {
      background-color: #6A6B6C;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  .searchHighlight {
    background: $dropdownColor;
    color: $navFontColor;
    cursor: pointer;
  }

  &:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $dropdownColor;
    position: absolute;
    top: -7px;
    left: 9px;
  }

  &:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $dropdownColor;
    position: absolute;
    top: -6px;
    left: 10px;
  }
}
