// Variables.scss
// Variables to customize the look and feel of Bootstrap
// -----------------------------------------------------



// GLOBAL VALUES
// --------------------------------------------------


// Grays
// -------------------------
$black:                 #000;
$grayDarker:            #222;
$grayDark:              #3B3835;
$gray:                  #555;
$shadedGray:            #707070;
$grayLight:             #999;
$grayLighter:           #eee;
$grayDot:               #eba;
$grayDull:              #bbb;
$white:                 #fff;


// SlideShare Bigfoot Base
// -------------------------
$brownDarker:           $grayDark;
$brownDark:             lighten($brownDarker, 20%);
$brown:                 lighten($brownDarker, 40%);
$brownLight:            #DDDCD7;
$brownLighter:          #EBEAE4;
$brownLightest:         #F6F5EF;
$offWhite:              #FBFDF7;


// Accent colors
// -------------------------
$blue:                  #049cdb;
$blueDark:              #0064cd;
$green:                 #46a546;
$red:                   #9d261d;
$brightRed:             #e00;
$yellow:                #ffc40d;
$yellowLight:           lighten($yellow, 40%);
$orange:                #D76C03;
$orangeLight:           #E98325;
$orangeLighter:         #FCBE47;
$pink:                  #c3325f;
$purple:                #7a43b6;

// Social media colors
$facebookColor: #3D5E99;
$googleColor: #e04006;
$twitterColor: #2CAFF0;
$linkedinColor: #007BB6;
$rssColor: #F6A832;
$textColor: #39b327;
$emailColor: #bbc4c8;
$pinterestColor: #cb1f27;

// Scaffolding
// -------------------------
$bodyBackground:        $grayLighter;
$textColor:             $brownDarker;


// Links
// -------------------------
$linkColor:             #07A;
$linkColorHover:        darken($linkColor, 10%);


// Typography
// -------------------------
$baseFontSize:          13px;
$baseFontFamily:        "Source Sans Pro", sans-serif;
$baseLineHeight:        18px;
$altFontFamily:         Georgia, "Times New Roman", Times, serif;

$headingsFontFamily:    inherit; // empty to use BS default, $baseFontFamily
$headingsFontWeight:    bold;    // instead of browser default, bold
$headingsColor:         inherit; // empty to use BS default, $textColor


// Tables
// -------------------------
$tableBackground:                   transparent; // overall background-color
$tableBackgroundAccent:             lighten($brownLightest, 2%); // for striping
$tableBackgroundHover:              $brownLightest; // for hover
$tableBorder:                       $brownLight; // table and cell border


// Buttons
// -------------------------
$btnBackground:                     $brownLightest;
$btnBackgroundHighlight:            darken($white, 10%);
$btnBorder:                         $brownLight;

$btnPrimaryBackground:              $orangeLighter;
$btnPrimaryBackgroundHighlight:     $orange;

$btnInfoBackground:                 $linkColor;
$btnInfoBackgroundHighlight:        $btnInfoBackground;

$btnSuccessBackground:              #62c462;
$btnSuccessBackgroundHighlight:     #51a351;

$btnWarningBackground:              lighten($orange, 15%);
$btnWarningBackgroundHighlight:     $orange;

$btnDangerBackground:               #ee5f5b;
$btnDangerBackgroundHighlight:      #bd362f;

$btnInverseBackground:              $brownDark;
$btnInverseBackgroundHighlight:     $brownDarker;


// Forms
// -------------------------
$inputBackground:               $white;
$inputBorder:                   #ccc;
$inputDisabledBackground:       $grayLighter;


// Dropdowns
// -------------------------
$dropdownBackground:            $brownLightest;
$dropdownBorder:                rgba(0,0,0,.2);
$dropdownLinkColor:             $brownDarker;
$dropdownLinkColorHover:        $brownLightest;
$dropdownLinkBackgroundHover:   $brownDark;

// Toolbar
// --------------------------------------
$toolbarBackground: #323232;
$progressbarBackground: #505050;

// COMPONENT VARIABLES
// --------------------------------------------------

// Z-index master list
// -------------------------
// Used for a bird's eye view of components dependent on the z-axis
// Try to avoid customizing these :)
$zindexDropdown:          1000;
$zindexPopover:           1010;
$zindexTooltip:           1020;
$zindexFixedNavbar:       1030;
$zindexModalBackdrop:     1040;
$zindexModal:             1050;


// Sprite icons path
// -------------------------
$iconSpritePath:      "/bigboot/img/glyphicons-halflings.png";
$iconWhiteSpritePath: "/bigboot/img/glyphicons-halflings-white.png";
$spritePath:          "/images/sprite-bigboot.png";

// Input placeholder text color
// -------------------------
$placeholderText:         $grayLight;


// Hr border color
// -------------------------
$hrBorder:                $grayLighter;


// Navbar
// -------------------------
$navbarHeight:                    44px;
$navbarBackground:                $grayDarker;
$navbarBackgroundHighlight:       $brownDarker;

$navbarText:                      $brownLight;
$navbarLinkColor:                 $brownLight;
$navbarLinkColorHover:            $white;
$navbarLinkColorActive:           $navbarLinkColorHover;
$navbarLinkBackgroundHover:       transparent;
$navbarLinkBackgroundActive:      $navbarBackground;

$navbarSearchBackground:          lighten($navbarBackground, 25%);
$navbarSearchBackgroundFocus:     $brownLightest;
$navbarSearchBorder:              darken($navbarSearchBackground, 30%);
$navbarSearchPlaceholderColor:    $black;


// Hero unit
// -------------------------
$heroUnitBackground:              $grayLighter;
$heroUnitHeadingColor:            inherit;
$heroUnitLeadColor:               inherit;


// Form states and alerts
// -------------------------
$warningText:             #c09853;
$warningBackground:       #fcf8e3;
$warningBorder:           darken(adjust-hue($warningBackground, -10), 3%);

$errorText:               #b94a48;
$errorBackground:         #f2dede;
$errorBorder:             darken(adjust-hue($errorBackground, -10), 3%);

$successText:             #468847;
$successBackground:       #dff0d8;
$successBorder:           darken(adjust-hue($successBackground, -10), 5%);

$infoText:                $linkColor;
$infoBackground:          #d9edf7;
$infoBorder:              darken(adjust-hue($infoBackground, -10), 7%);


// GRID
// --------------------------------------------------

// Default 940px grid
// -------------------------
$gridWidth:               940px;
$gridColumns:             12;
$gridColumnWidth:         60px;
$gridGutterWidth:         20px;
$gridRowWidth:            ($gridColumns * $gridColumnWidth) + ($gridGutterWidth * ($gridColumns - 1));

$sidebarPadding:          20px;
$sidebarColor:            $brownLighter;
$sidebarBorderColor:      $brownLight;
$sidebarBorderWidth:      1px;

// Fluid grid
// -------------------------
$fluidGridColumnWidth:    6.382978723%;
$fluidGridGutterWidth:    2.127659574%;

// Device widths (dimensions lifted from Foundation)
// -------------------------
$foundationSmallMax: 641px;
$foundationExtraSmallMax: 350px;
