// UTILITY CLASSES
// ---------------

// Quick floats
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

// Toggling content
.hide {
  display: none;
}
.show {
  display: block;
}

// Visibility
.invisible {
  visibility: hidden;
}

// Makes element visually hidden, but exposed to assistive tech for accessibility.
// https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}