//
// Tables.scss
// Tables for, you guessed it, tabular data
// ----------------------------------------
@import "variables.scss"; // Modify this for custom colors, font-sizes, etc
@import "mixins.scss";


// BASE TABLES
// -----------------

table {
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: $tableBackground;
}

// BASELINE STYLES
// ---------------

.table {
  width: 100%;
  margin-bottom: $baseLineHeight;
  // Cells
  th,
  td {
    padding: 8px;
    line-height: $baseLineHeight;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid $tableBorder;
  }
  th {
    font-weight: bold;
  }
  // Bottom align for column headings
  thead th {
    vertical-align: bottom;
  }
  // Remove top border from thead by default
  colgroup + thead tr:first-child th,
  colgroup + thead tr:first-child td,
  thead:first-child tr:first-child th,
  thead:first-child tr:first-child td {
    border-top: 0;
  }
  // Account for multiple tbody instances
  tbody + tbody {
    border-top: 2px solid $tableBorder;
  }
}



// CONDENSED TABLE W/ HALF PADDING
// -------------------------------

.table-condensed {
  th,
  td {
    padding: 4px 5px;
  }
}


// BORDERED VERSION
// ----------------

.table-bordered {
  border: 1px solid $tableBorder;
  border-left: 0;
  border-collapse: separate; // Done so we can round those corners!
  *border-collapse: collapsed; // IE7 can't round corners anyway
  @include border-radius(4px);
  th,
  td {
    border-left: 1px solid $tableBorder;
  }
  // Prevent a double border
  thead:first-child tr:first-child th,
  tbody:first-child tr:first-child th,
  tbody:first-child tr:first-child td {
    border-top: 0;
  }
  // For first th or td in the first row in the first thead or tbody
  thead:first-child tr:first-child th:first-child,
  tbody:first-child tr:first-child td:first-child {
    @include border-radius(4px 0 0 0);
  }
  thead:first-child tr:first-child th:last-child,
  tbody:first-child tr:first-child td:last-child {
    @include border-radius(0 4px 0 0);
  }
  // For first th or td in the first row in the first thead or tbody
  thead:last-child tr:last-child th:first-child,
  tbody:last-child tr:last-child td:first-child {
    @include border-radius(0 0 0 4px);
  }
  thead:last-child tr:last-child th:last-child,
  tbody:last-child tr:last-child td:last-child {
    @include border-radius(0 0 4px 0);
  }
}


// ZEBRA-STRIPING
// --------------

// Default zebra-stripe styles (alternating gray and transparent backgrounds)
.table-striped {
  tbody {
    tr:nth-child(odd) td,
    tr:nth-child(odd) th {
      background-color: $tableBackgroundAccent;
    }
  }
}


// HOVER EFFECT
// ------------
// Placed here since it has to come after the potential zebra striping
.table {
  tbody tr:hover td,
  tbody tr:hover th {
    background-color: $tableBackgroundHover;
  }
}


// TABLE CELL SIZING
// -----------------

// Change the columns
table {
  .span1     { @include tableColumns(1); }
  .span2     { @include tableColumns(2); }
  .span3     { @include tableColumns(3); }
  .span4     { @include tableColumns(4); }
  .span5     { @include tableColumns(5); }
  .span6     { @include tableColumns(6); }
  .span7     { @include tableColumns(7); }
  .span8     { @include tableColumns(8); }
  .span9     { @include tableColumns(9); }
  .span10    { @include tableColumns(10); }
  .span11    { @include tableColumns(11); }
  .span12    { @include tableColumns(12); }
  .span13    { @include tableColumns(13); }
  .span14    { @include tableColumns(14); }
  .span15    { @include tableColumns(15); }
  .span16    { @include tableColumns(16); }
  .span17    { @include tableColumns(17); }
  .span18    { @include tableColumns(18); }
  .span19    { @include tableColumns(19); }
  .span20    { @include tableColumns(20); }
  .span21    { @include tableColumns(21); }
  .span22    { @include tableColumns(22); }
  .span23    { @include tableColumns(23); }
  .span24    { @include tableColumns(24); }
}
