// ALERT STYLES
// ------------
@import "./variables";
@import "./mixins";
@import "app/assets/stylesheets/base/foundation-settings";

// Base alert styles
.alert {
  padding: 8px 35px 8px 14px;
  margin-bottom: $baseLineHeight;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: $warningBackground;
  border: 1px solid $warningBorder;
  @include border-radius(4px);
  color: $warningText;
}
.alert-heading {
  color: inherit;
}

// Adjust close link position
.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: 18px;
}

// Alternate styles
// ----------------

.alert-success {
  background-color: $successBackground;
  border-color: $successBorder;
  color: $successText;
}
.alert-danger,
.alert-error {
  background-color: $errorBackground;
  border-color: $errorBorder;
  color: $errorText;
}
.alert-info {
  background-color: $infoBackground;
  border-color: $infoBorder;
  color: $infoText;
}
.alert-neutral {
  background-color: $blue-gray-100;
  border-color: $blue-gray-300;
  color: $blue-gray-700;
}

// Custom alert style - used in fb notice
.alert-message {
  background-color: $gray;
  border: 1px solid $grayLight;
  color: $white;
  margin: 5px auto 10px;
  width: 937px;
  padding: 9px 10px;
}

// Styles specific to fb alert message
.alert-message.fb-alert {
  background: #777 url(/slideview/images/sprite-slideview.png) -240px -139px no-repeat;
  color: $grayLighter;
  padding: 10px 10px 8px 32px;
  position: relative;
  text-shadow: none;
  width: 917px;

  .close {
    background: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 1em;
    opacity: 0.7;
    position: absolute;
    right: 10px;
    text-shadow: 0 1px 0 #ccc;
    top: 8px;
  }

  a {
    color: #58c4f8;
    text-decoration: underline;
  }
}

// Block alerts
// ------------------------
.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}

.notification {
  background: #336887;
  margin: 0;
  padding: 10px 14px;
  font-size: 16px;
  color: #fff;
  font-weight: 100;
  text-align: center;
  line-height: 30px;
  letter-spacing: 0.05em;
  a {
    color: #73bef8;
  }
  a.block {
    display: inline-block;
    margin-left: 6px;
  }
}

.close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: "alpha(opacity=20)";
}
