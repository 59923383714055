// BUTTON GROUPS
// -------------
@import "mixins.scss";
@import "variables.scss";

// Make the div behave like a button
.btn-group {
  position: relative;
  @include clearfix(); // clears the floated buttons
  @include ie7-restore-left-whitespace();
}

// Space out series of button groups
.btn-group + .btn-group {
  margin-left: 5px;
}

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
  margin-top: $baseLineHeight * 0.5;
  margin-bottom: $baseLineHeight * 0.5;
  .btn-group {
    display: inline-block;
    @include ie7-inline-block();
  }
}

// Float them, remove border radius, then re-add to first and last elements
.btn-group .btn {
  position: relative;
  float: left;
  margin-left: -1px;
  @include border-radius(0);
}
// Set corners individual because sometimes a single button can be in a .btn-group and we need :first-child and :last-child to both match
.btn-group .btn:first-child {
  margin-left: 0;
     -webkit-border-top-left-radius: 4px;
         -moz-border-radius-topleft: 4px;
             border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
      -moz-border-radius-bottomleft: 4px;
          border-bottom-left-radius: 4px;
}
.btn-group .btn:last-child,
.btn-group .dropdown-toggle {
     -webkit-border-top-right-radius: 4px;
         -moz-border-radius-topright: 4px;
             border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
      -moz-border-radius-bottomright: 4px;
          border-bottom-right-radius: 4px;
}
// Reset corners for large buttons
.btn-group .btn.large:first-child {
  margin-left: 0;
     -webkit-border-top-left-radius: 6px;
         -moz-border-radius-topleft: 6px;
             border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
      -moz-border-radius-bottomleft: 6px;
          border-bottom-left-radius: 6px;
}
.btn-group .btn.large:last-child,
.btn-group .large.dropdown-toggle {
     -webkit-border-top-right-radius: 6px;
         -moz-border-radius-topright: 6px;
             border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
      -moz-border-radius-bottomright: 6px;
          border-bottom-right-radius: 6px;
}

// On hover/focus/active, bring the proper btn to front
.btn-group .btn:hover,
.btn-group .btn:focus,
.btn-group .btn:active,
.btn-group .btn.active {
  z-index: 2;
}

// On active and open, don't show outline
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}



// Split button dropdowns
// ----------------------

// Give the line between buttons some depth
.btn-group .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  $shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  @include box-shadow($shadow);
  *padding-top: 3px;
  *padding-bottom: 3px;
}
.btn-group .btn-mini.dropdown-toggle {
  padding-left: 5px;
  padding-right: 5px;
  *padding-top: 1px;
  *padding-bottom: 1px;
}
.btn-group .btn-small.dropdown-toggle {
  *padding-top: 4px;
  *padding-bottom: 4px;
}
.btn-group .btn-large.dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}

.btn-group.open {
  // IE7's z-index only goes to the nearest positioned ancestor, which would
  // make the menu appear below buttons that appeared later on the page
  *z-index: $zindexDropdown;

  // Reposition menu on open and round all corners
  .dropdown-menu {
    display: block;
    margin-top: 1px;
    @include border-radius(5px);
  }

  .dropdown-toggle {
    background-image: none;
    $shadow: inset 0 1px 6px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
    @include box-shadow($shadow);
  }
}

// Reposition the caret
.btn .caret {
  margin-top: 7px;
  margin-left: 0;
}
.btn:hover:not(.disabled) .caret,
.open.btn-group .caret {
  @include opacity(100);
}
// Carets in other button sizes
.btn-mini .caret {
  margin-top: 5px;
}
.btn-small .caret {
  margin-top: 6px;
}
.btn-large .caret {
  margin-top: 6px;
  border-left:  5px solid transparent;
  border-right: 5px solid transparent;
  border-top:   5px solid $black;
}


// Account for other colors
.btn-primary,
.btn-warning,
.btn-danger,
.btn-info,
.btn-success,
.btn-inverse {
  .caret {
    border-top-color: $white;
    border-bottom-color: $white;
    @include opacity(75);
  }
}

