// Core variables and mixins
@import "variables.scss"; // Modify this for custom colors, font-sizes, etc
@import "mixins.scss";

// SPRITES
// Glyphs and icons for buttons, nav, and more
// -------------------------------------------


// ICONS
// -----

// All icons receive the styles of the <i> tag with a base class 
// of .i and are then given a unique class to add width, height, 
// and background-position. Your resulting HTML will look like
// <i class="iconTemp-inbox"></i>.

// For the white version of the icons, just add the .iconTemp-white class:
// <i class="iconTemp-inbox iconTemp-white"></i>

[class^="iconTemp-"],
[class*=" iconTemp-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url($iconSpritePath);
  background-position: 14px 14px;
  background-repeat: no-repeat;

 @include ie7-restore-right-whitespace(); 
}
.iconTemp-white {
  background-image: url($iconWhiteSpritePath);
}

.iconTemp-glass              { background-position: 0      0; }
.iconTemp-music              { background-position: -24px  0; }
.iconTemp-search             { background-position: -48px  0; }
.iconTemp-envelope           { background-position: -72px  0; }
.iconTemp-heart              { background-position: -96px  0; }
.iconTemp-star               { background-position: -120px 0; }
.iconTemp-star-empty         { background-position: -144px 0; }
.iconTemp-user               { background-position: -168px 0; }
.iconTemp-film               { background-position: -192px 0; }
.iconTemp-th-large           { background-position: -216px 0; }
.iconTemp-th                 { background-position: -240px 0; }
.iconTemp-th-list            { background-position: -264px 0; }
.iconTemp-ok                 { background-position: -288px 0; }
.iconTemp-remove             { background-position: -312px 0; }
.iconTemp-zoom-in            { background-position: -336px 0; }
.iconTemp-zoom-out           { background-position: -360px 0; }
.iconTemp-off                { background-position: -384px 0; }
.iconTemp-signal             { background-position: -408px 0; }
.iconTemp-cog                { background-position: -432px 0; }
.iconTemp-trash              { background-position: -456px 0; }

.iconTemp-home               { background-position: 0      -24px; }
.iconTemp-file               { background-position: -24px  -24px; }
.iconTemp-time               { background-position: -48px  -24px; }
.iconTemp-road               { background-position: -72px  -24px; }
.iconTemp-download-alt       { background-position: -96px  -24px; }
.iconTemp-download           { background-position: -120px -24px; }
.iconTemp-upload             { background-position: -144px -24px; }
.iconTemp-inbox              { background-position: -168px -24px; }
.iconTemp-play-circle        { background-position: -192px -24px; }
.iconTemp-repeat             { background-position: -216px -24px; }
.iconTemp-refresh            { background-position: -240px -24px; }
.iconTemp-list-alt           { background-position: -264px -24px; }
.iconTemp-lock               { background-position: -287px -24px; } // 1px off
.iconTemp-flag               { background-position: -312px -24px; }
.iconTemp-headphones         { background-position: -336px -24px; }
.iconTemp-volume-off         { background-position: -360px -24px; }
.iconTemp-volume-down        { background-position: -384px -24px; }
.iconTemp-volume-up          { background-position: -408px -24px; }
.iconTemp-qrcode             { background-position: -432px -24px; }
.iconTemp-barcode            { background-position: -456px -24px; }

.iconTemp-tag                { background-position: 0      -48px; }
.iconTemp-tags               { background-position: -25px  -48px; } // 1px off
.iconTemp-book               { background-position: -48px  -48px; }
.iconTemp-bookmark           { background-position: -72px  -48px; }
.iconTemp-print              { background-position: -96px  -48px; }
.iconTemp-camera             { background-position: -120px -48px; }
.iconTemp-font               { background-position: -144px -48px; }
.iconTemp-bold               { background-position: -167px -48px; } // 1px off
.iconTemp-italic             { background-position: -192px -48px; }
.iconTemp-text-height        { background-position: -216px -48px; }
.iconTemp-text-width         { background-position: -240px -48px; }
.iconTemp-align-left         { background-position: -264px -48px; }
.iconTemp-align-center       { background-position: -288px -48px; }
.iconTemp-align-right        { background-position: -312px -48px; }
.iconTemp-align-justify      { background-position: -336px -48px; }
.iconTemp-list               { background-position: -360px -48px; }
.iconTemp-indent-left        { background-position: -384px -48px; }
.iconTemp-indent-right       { background-position: -408px -48px; }
.iconTemp-facetime-video     { background-position: -432px -48px; }
.iconTemp-picture            { background-position: -456px -48px; }

.iconTemp-pencil             { background-position: 0      -72px; }
.iconTemp-map-marker         { background-position: -24px  -72px; }
.iconTemp-adjust             { background-position: -48px  -72px; }
.iconTemp-tint               { background-position: -72px  -72px; }
.iconTemp-edit               { background-position: -96px  -72px; }
.iconTemp-share              { background-position: -120px -72px; }
.iconTemp-check              { background-position: -144px -72px; }
.iconTemp-move               { background-position: -168px -72px; }
.iconTemp-step-backward      { background-position: -192px -72px; }
.iconTemp-fast-backward      { background-position: -216px -72px; }
.iconTemp-backward           { background-position: -240px -72px; }
.iconTemp-play               { background-position: -264px -72px; }
.iconTemp-pause              { background-position: -288px -72px; }
.iconTemp-stop               { background-position: -312px -72px; }
.iconTemp-forward            { background-position: -336px -72px; }
.iconTemp-fast-forward       { background-position: -360px -72px; }
.iconTemp-step-forward       { background-position: -384px -72px; }
.iconTemp-eject              { background-position: -408px -72px; }
.iconTemp-chevron-left       { background-position: -432px -72px; }
.iconTemp-chevron-right      { background-position: -456px -72px; }

.iconTemp-plus-sign          { background-position: 0      -96px; }
.iconTemp-minus-sign         { background-position: -24px  -96px; }
.iconTemp-remove-sign        { background-position: -48px  -96px; }
.iconTemp-ok-sign            { background-position: -72px  -96px; }
.iconTemp-question-sign      { background-position: -96px  -96px; }
.iconTemp-info-sign          { background-position: -120px -96px; }
.iconTemp-screenshot         { background-position: -144px -96px; }
.iconTemp-remove-circle      { background-position: -168px -96px; }
.iconTemp-ok-circle          { background-position: -192px -96px; }
.iconTemp-ban-circle         { background-position: -216px -96px; }
.iconTemp-arrow-left         { background-position: -240px -96px; }
.iconTemp-arrow-right        { background-position: -264px -96px; }
.iconTemp-arrow-up           { background-position: -289px -96px; } // 1px off
.iconTemp-arrow-down         { background-position: -312px -96px; }
.iconTemp-share-alt          { background-position: -336px -96px; }
.iconTemp-resize-full        { background-position: -360px -96px; }
.iconTemp-resize-small       { background-position: -384px -96px; }
.iconTemp-plus               { background-position: -408px -96px; }
.iconTemp-minus              { background-position: -433px -96px; }
.iconTemp-asterisk           { background-position: -456px -96px; }

.iconTemp-exclamation-sign   { background-position: 0      -120px; }
.iconTemp-gift               { background-position: -24px  -120px; }
.iconTemp-leaf               { background-position: -48px  -120px; }
.iconTemp-fire               { background-position: -72px  -120px; }
.iconTemp-eye-open           { background-position: -96px  -120px; }
.iconTemp-eye-close          { background-position: -120px -120px; }
.iconTemp-warning-sign       { background-position: -144px -120px; }
.iconTemp-plane              { background-position: -168px -120px; }
.iconTemp-calendar           { background-position: -192px -120px; }
.iconTemp-random             { background-position: -216px -120px; }
.iconTemp-comment            { background-position: -240px -120px; }
.iconTemp-magnet             { background-position: -264px -120px; }
.iconTemp-chevron-up         { background-position: -288px -120px; }
.iconTemp-chevron-down       { background-position: -313px -119px; } // 1px off
.iconTemp-retweet            { background-position: -336px -120px; }
.iconTemp-shopping-cart      { background-position: -360px -120px; }
.iconTemp-folder-close       { background-position: -384px -120px; }
.iconTemp-folder-open        { background-position: -408px -120px; }
.iconTemp-resize-vertical    { background-position: -432px -119px; }
.iconTemp-resize-horizontal  { background-position: -456px -118px; }
