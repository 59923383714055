//
// Layouts
// Fixed-width and fluid (with sidebar) layouts
// --------------------------------------------
@import "variables.scss"; // Modify this for custom colors, font-sizes, etc
@import "mixins.scss";


// Container (centered, fixed-width layouts)
.container {
  @include container-fixed();
}

// Fluid layouts (left aligned, with sidebar, min- & max-width content)
.container-fluid {
  padding-left: $gridGutterWidth;
  padding-right: $gridGutterWidth;
  @include clearfix();
}

// Responsive container
.container-responsive {
  max-width: $gridWidth;
  margin: 0 auto;
  padding: 20px 0 20px 0;
  width: 100%;
}

#main-content {
  min-height: 90%;
  position: relative;
}

// No page scroll class
body.noscroll {
  position: fixed;
  overflow: hidden;
}
