// BADGES
// ------
@import "variables.scss";
@import "mixins.scss";

// Base
.badge {
  padding: 1px 9px 2px;
  font-size: $baseFontSize * .925;
  font-weight: bold;
  white-space: nowrap;
  color: $white;
  background-color: $grayLight;
  @include border-radius(9px);
}

// Hover state
.badge:hover {
  color: $white;
  text-decoration: none;
  cursor: pointer;
}

// Colors
.badge-error            { background-color: $errorText; }
.badge-error:hover      { background-color: darken($errorText, 10%); }

.badge-warning          { background-color: $orange; }
.badge-warning:hover    { background-color: darken($orange, 10%); }

.badge-success          { background-color: $successText; }
.badge-success:hover    { background-color: darken($successText, 10%); }

.badge-info             { background-color: $infoText; }
.badge-info:hover       { background-color: darken($infoText, 10%); }

.badge-inverse          { background-color: $grayDark; }
.badge-inverse:hover    { background-color: darken($grayDark, 10%); }