// MODALS
// ------
@import "variables.scss"; // Modify this for custom colors, font-sizes, etc
@import "mixins.scss";

// Recalculate z-index where appropriate
.modal-open {
  .dropdown-menu {  z-index: $zindexDropdown + $zindexModal; }
  .dropdown.open { *z-index: $zindexDropdown + $zindexModal; }
  .popover       {  z-index: $zindexPopover  + $zindexModal; }
  .tooltip       {  z-index: $zindexTooltip  + $zindexModal; }
}

// Background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindexModalBackdrop;
  background-color: $black;
  // Fade for backdrop
  &.fade { opacity: 0; }
}

.modal-backdrop,
.modal-backdrop.fade.in {
  @include opacity(80);
}

// Base modal
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: $zindexModal;
  overflow: auto;
  width: 200px;
  margin: -250px 0 0 -280px;
  background-color: $white;
  border: 1px solid #999;
  border: 1px solid rgba(0,0,0,.3);
  *border: 1px solid #999; /* IE6-7 */
  @include border-radius(6px);
  @include box-shadow(0 3px 7px rgba(0,0,0,0.3));
  @include background-clip(padding-box);
  &.fade {
    @include transition(e('opacity .3s linear, top .3s ease-out'));
    top: -25%;
  }
  &.fade.in { top: 50%; }
}
.modal-header {
  padding: 9px 15px;
  border-bottom: 1px solid #eee;
  // Close icon
  .close { margin-top: 2px; }
}

// Body (where all modal content resises)
.modal-body {
  overflow-y: auto;
  max-height: 400px;
  padding: 15px;
}
// Remove bottom margin if need be
.modal-form {
  margin-bottom: 0;
}

// Footer (for actions)
.modal-footer {
  padding: 14px 15px 15px;
  margin-bottom: 0;
  text-align: right; // right align buttons
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  @include border-radius(0 0 6px 6px);
  @include box-shadow(inset 0 1px 0 $white);
  @include clearfix(); // clear it in case folks use .pull-* classes on buttons

  // Properly space out buttons
  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
}
.modal.FBlogin {
  height: 463px;
  margin-left: -220px;
}
